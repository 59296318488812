import React from "react";
import './List.scss';

export interface IList {
    selected?: string;
    lable?: string;
    list: {name: string, icon?: string} [];
    className?: string;
    setValue: (event: any) => void;
}
export default function List({list, setValue, selected, lable, className}: IList) {
    return (
        <div className={`List ${className}`}>            
            {lable ? (<label>{lable}</label>) : ""}
            <ul>
                {list.map((option, index) => (<li className={(selected === option.name) ? "List__selected" : ""} key={index} onClick={() => setValue(option.name)}>{option.icon && <i className={option.icon}></i>}{option.name}</li>))}
            </ul>
        </div>
    )
}

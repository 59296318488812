import React from 'react';
import Logo from 'src/components/logo/Logo';
import MainMenu from 'src/components/MainMenu/MainMenu';
import './Menu.scss'
import { WEBSITE_VERSION } from 'src/config';

export default function Menu() {
    return (
        <div className="Menu">
            <div className='Menu__nav'>
                <Logo />
                <MainMenu />                
                <div className="Menu__nav__version">© 2023 Ebmbook Central v{WEBSITE_VERSION}</div>
            </div>
        </div>
    )
}
import React from "react";
import './Selection.scss';

export interface ISelection {
    selected?: string | number;
    lable?: string;
    value?: string;
    name?: string;
    hasIcon?: boolean;
    /** default option such as please select */
    pleaseSelect?: string;
    list: any[];
    /** use the two properties of the object in the list - text in html and value as value of the input */
    listShowBy?: {text: any, value: any}
    isError?: boolean;
    setValue: (event: any) => void;
}
export default function Selection({lable, list, listShowBy, setValue, selected, hasIcon, pleaseSelect, isError}: ISelection){

    const options = () => {
        if (listShowBy?.text) {       // use properties of the object
            const text = listShowBy.text;
            const value = listShowBy.value;
            return (
                list.map((option, index) => (<option key={index} value={option[value]}>{option[text]}</option>))
            )            
        }
        
        const hasID = list.some((obj) => obj?.hasOwnProperty("id"));
        if (hasIcon) {      // with icon
            return (
                list.map((option, index) => (<option key={index} value={option?.name}>{option.icon && <i className={option.icon} />} {option.name}</option>))
            )
            
        } else if (hasID) { // use id as return value
            return (
                list.map((option, index) => (<option key={index} value={option?.id}>{option.name}</option>))
            )

        } else {            // standard
            return (
                list.map((option, index) => (<option key={index} value={option}>{option}</option>))
            )

        }
    }

    return (
        <div className={`Selection ${isError ? "g_error_line" : ""}`}>
            {lable ? (<label>{lable}</label>) : ""}
            <select defaultValue={selected} onChange={setValue}>
                {pleaseSelect && <option key={null} value={pleaseSelect}>{pleaseSelect}</option>}
                {options()}
            </select>
        </div>
    )
}

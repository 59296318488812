import React, {useEffect, useState} from 'react';
import { useStripe, PaymentElement, useElements } from '@stripe/react-stripe-js';
import { PaymentInit, PaymentStatusURL } from 'src/api/Endpoints';
import { sendRequest } from 'src/api/SendRequest';
import { RequestType } from 'src/types';
import { DEV_ENV, DOMAIN_URL } from 'src/config';
import Warning from '../Warning/Warning';
import ButtonSubmit from '../ButtonSubmit/ButtonSubmit';
import Loader from '../Loader/Loader';
import { IPackagePriceType } from 'src/interfaces';
import { convertStripeAmountToNormal } from 'src/lib';

import "./CheckoutForm.scss";

interface ICheckoutForm {
  PackageName: string;
  PackagePriceTypeSelected: IPackagePriceType;
  autoRenew?: boolean;
  changeLicenseType: () => any;
}
export default function CheckoutForm({PackageName, PackagePriceTypeSelected, autoRenew, changeLicenseType}: ICheckoutForm) {
  const stripe = useStripe();
  const elements = useElements();

  const [errorMessage, setErrorMessage] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("Please wait loading payment processor..");

  const amount = PackagePriceTypeSelected.price;

  const handleError = (error: any) => {
    setLoading(false);
    setErrorMessage(error);
  }

  // check stripe connection status after few seconds
  useEffect(() => {
    if (!stripe || !elements) {
      setTimeout(() => {
        setLoadingMsg("Still loading, make sure you have internet connection..")
      }, 3000);
    }

  }, [stripe, elements]);

  
  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent!.status) {
        case "succeeded":
          setMessage("Payment succeeded!");
          break;
        case "processing":
          setMessage("Your payment is processing.");
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          break;
        default:
          setMessage("Something went wrong.");
          break;
      }
    });
  }, [stripe]);

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setLoading(true);

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit();
    if (submitError) {
      handleError(submitError.message);
      return;
    }

    // Create the PaymentIntent and obtain clientSecret
    const res = await sendRequest({api: PaymentInit, params: {PackageName, autoRenew, PackagePriceType: {...PackagePriceTypeSelected, extras: undefined}}, rtype: RequestType.POST, cacheOff: true});
    const clientSecret = (res.status === 200 && res.data.client_secret) ? res.data.client_secret : undefined;
    if(!clientSecret) {
      handleError("The payment processor is down - please report this error (pay001) to ebmbook support!");
      return;
    }
    const orderID = res.data.orderID;

    // Confirm the PaymentIntent using the details collected by the Payment Element
    const returnURL = `${DOMAIN_URL}/packages/payment/?order=${orderID}&checkstatus=1`
    const {error} = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: returnURL,
      },
    });

    if (error) {
      // This point is only reached if there's an immediate error when
      // confirming the payment. Show the error to your customer (for example, payment details incomplete)
      handleError(error.message); 
      console.log(returnURL, "error inf: ", error);
      // at the background, update the status with this new payment's status - in order to change processing to new code
      if (orderID) {
        try {
          await sendRequest({api: `${PaymentStatusURL}/${orderID}`, rtype: RequestType.GET});          
        } catch (errorX) {
            console.error("Ops, wrong response from API:", errorX);
        }
      }

    } else {
      // Your customer is redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer is redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  };
  
  return (
    <form className='CheckoutForm' onSubmit={handleSubmit}>
      {(!stripe || !elements) ? 
        <Loader classNames='center-screen' loading={true} msg={loadingMsg} /> 
        : 
        <>
        <h3>Payment Term {PackagePriceTypeSelected.term}</h3>
        <PaymentElement id="payment-element" options={{layout: "tabs"}} />  
        <div className='CheckoutForm__section'>
          <ButtonSubmit value={`Pay Now (${convertStripeAmountToNormal(amount)})`} loading={loading} />
          {!loading && <span className='CheckoutForm__section__group'>
            Or, <span className='g_link' onClick={() => changeLicenseType()}>Change Payment Term</span>            
          </span>  
          }
          
          {PackagePriceTypeSelected.mode === "subscription" && <p className='CheckoutForm__subtitle small'>{!autoRenew && "Note: You have chosen not to auto renew your license in future!"}</p>}
     
        </div>
        </>
      }
      {/* Show any error or success messages */}
      {message && <div className='CheckoutForm__message'>{message}</div>}      
      {errorMessage && <Warning msg={errorMessage} reset={() => setErrorMessage("")} />}

      {DEV_ENV && <div className='space10'>
        <p>Hey tester, use the following card numbers for testing purpose only: </p>
        <small>
          <div>United Kingdom (GB)	4000008260000000	Visa</div>
          <div>United Kingdom (GB)	4000058260000005	Visa (debit)</div>
          <div>United Kingdom (GB)	5555558265554449	Mastercard</div>
        </small>
      </div>}

    </form>
  );
}
import { IPurchase } from "src/interfaces";
import { dateDaysDifference } from ".";

export const getSubscriptionWarning = (entitlementX?: IPurchase) => {
    if (entitlementX) {
        const subName = `${entitlementX.packageName} (${entitlementX.id})`
        if (entitlementX.status === "succeeded") {
            if (isEntitlementExpired(entitlementX)) {
                return `Subscription has been expired - ${subName}!`
            } 
            
            if (entitlementX?.dueDate) {
                const days = dateDaysDifference(new Date(), new Date(entitlementX.dueDate));
                if (days < 30) {
                    if (days < 0) {
                        return `Warning: subscription has been expired - ${subName}!`;
                    } else if (days < 10) {
                        return `Warning: in order to avoid disruption to your business, subscription should be renewed within ${days} days - ${subName}!`;
                    } else if (days < 20) {
                        return `Warning: subscription is due to expire in ${days} days - ${subName}!`;
                    }
                    return `Subscription will expire in ${days} days - ${subName}!`;
                }
            }
            
        }
    }
    return "";
}

export const isEntitlementExpired = (entitlementX: IPurchase) => {
    if (entitlementX?.dueDate) {
        const current = new Date();
        if (new Date(entitlementX.dueDate) >= current) {
            return false;
        }
    }

    return true;
}
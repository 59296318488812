import React, { useEffect, useState } from "react";

import "./AutoPopUp.scss";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/store";
import { resetActions } from "src/store/reducers/ActionsReducers";
import { ActionTypes } from "src/types";

export default function AutoPopupMessage () {
    const [show, setShow] = useState(false);
    const [isAlert, setAlert] = useState(false);
    const action = useSelector((state: RootState) => state.action);
    const dispatch = useDispatch();

    const [message, setRecentAction] = useState("");
    
    useEffect(() => {
        if(action.actions.length){
            const msgObj = action.actions[action.actions.length - 1];
            const msg = msgObj.value;
            if (msgObj.name === ActionTypes.ALERT) {
                setAlert(true);
            }
            console.log("new msg received: ", msg);
            setShow(true);
            setRecentAction(msg);
            // reset the redux actions state so to avoid overloading the size plus to show another action if triggered 
            dispatch(resetActions()); 
            // hide message after few seconds
            setTimeout(() => {
                setShow(false);
                setRecentAction("");
                setAlert(false);

            }, 5000);
        }

    }, [action.actions, dispatch]);
    
    
    return (
        <>
        {show &&         
        <div className='AutoPopupMessage' onClick={() => setShow(false)}>
            {isAlert ? <div className="AutoPopupMessage__alert">{message}</div> : <div className="AutoPopupMessage__message">{message}</div>}
        </div>
        }
        </>
    )
}

import { useApi } from "./useFetch";
import { RequestType } from "../types";
import { CategoriesApiURL } from "../api/Endpoints";
import { IRootCategory } from "src/interfaces";
import { useMemo } from "react";
import { getCountriesSupported } from "../lib/countries";

export const useCategory = (country: string) => {   
    // load all the categories
    const {response: categories, loading: loadingCats} = useApi({rtype: RequestType.GET, api: CategoriesApiURL});
    
    // load only selected country's categories
    const countryCategoriesDetail = useMemo(() => {
        const CountryObj = getCountriesSupported().find((countryA) => countryA.name === country);
        if (categories?.status === 200 && CountryObj) {
            const categoriesSelected = categories?.data?.data?.filter((countryX: IRootCategory) => countryX.country === CountryObj.code) as IRootCategory[];
            return categoriesSelected;
        }
        return [];
    }, [country, categories]);

    // category names only
    const countryCategories = (countryCategoriesDetail) ? countryCategoriesDetail?.map((c:IRootCategory) => c.name) : [];

    return {
        countryCategories, categories, countryCategoriesDetail, loadingCats
    }
}
import { AxiosError } from "axios";
import { MutableRefObject } from "react";
import { codeMessage } from "src/messages";

export const isEmail = (email: string) => {
    const validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (validRegex.test(email)) {
        return true;
    }
    return false;
}

/**
 * Password should be between 5 to 20 characters which contains at least one numeric digit, one uppercase and one lowercase letter
 * @param password string
 */
export const isPassword = (password: string) => {    
    const passw =  /^([A-Za-z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?!.*\s)(?!.*['"`]).{5,20}$/;
    if (passw.test(password)) {
        return true;
    }

    return false;
}
/**
 * Get error messages from the error code.
 * @see codeMessage
 */
export const getError = (exception: AxiosError<any>) =>{
    return codeMessage[exception?.response?.data?.message] || exception?.response?.statusText || exception?.message;
}

export const hashArgs = (...args: any[]) => {
    return args.reduce((acc, arg) => stringify(arg) + ":" + acc, "");
}
  
export const stringify = (val: any) => {
    return typeof val === "object" ? JSON.stringify(val) : String(val);
}

/**
 * Truncate string to short length followed by ..
 */
export const strShort = (val: string, max: number) => {
    if (val?.length > max) {
        return val.substring(0, max) + "..";
    }
    return val;
}

/**
 * Convert to JSON, if valid it will return a JSON object otherwise false.
 */
export const toJSON = (data: any) => {
    let ret = false;
    try {
        return JSON.parse(data);
    } catch (e) {
        ret = false;
    }
    return ret;
}

export const toCamelCase = (txt?: string) => {
    const text = txt?.toLocaleLowerCase()?.trim()?.split(" ")?.map(word => {
        const firstChar = word.substring(0, 1);
        return word.replace(firstChar, firstChar.toUpperCase());
    })?.join(" ");
    return (text) ? text : txt;
}

export const isMobile = () => {
    return Math.min(window.screen.width, window.screen.height) < 600 || navigator.userAgent.indexOf("Mobi") > -1
};
export const isTablet = () => {
    const minSize = Math.min(window.screen.width, window.screen.height);
    return minSize > 600 && minSize < 768;
};


export const scrollToRef = (myRef: MutableRefObject<any>) => {
    myRef?.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
}
/**
 * @example convert one pence which is 100 to 1.00 
 */
export const convertStripeAmountToNormal = (amount: number) => {
    if (amount.toString().length >= 3) {
        const newAmountSplit = amount.toString().split("");
        newAmountSplit.splice(newAmountSplit.length - 2, 0, ".");
        return newAmountSplit.join("");
    }
    return amount.toString();
}

export const dateToStr = (d?: Date) => {
    if (!d) {
        return "";
    }

    return new Date(d)?.toDateString();
}

export const dateDaysDifference = (date1: Date, date2: Date) => {
    const diffTime = Math.abs(date2.getTime() - date1.getTime());
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays;
}
import React from "react";
import { Link } from "react-router-dom";
import { useHeader } from "src/hooks/useHeader";
import { message } from "src/messages";

import "./Overview.scss";
import useAccount from "src/hooks/useAccount";
import Warning from "src/components/Warning/Warning";
import usePackages from "src/hooks/usePackages";
import { Pages } from "src/types";
import CopyToClipboard from "src/components/CopyToClipbaord/CopyToClipbaord";
import { useEntitlement } from "src/hooks/useEntitlement";
import Loader from "src/components/Loader/Loader";
import Icon from "src/components/Icon/Icon";


export default function Overview(){
    // set page title in header
    useHeader({title: message.Overview});
    const {packages: Packages} = usePackages();
    
    const {user} = useAccount();
    const { fullname, business } = user!;

    const {validEntitlements, hasPackage} = useEntitlement(business);
    const totalSubscriptions = validEntitlements?.length;
    
    return (
        <div className="Page Overview">
            <h2>Welcome {fullname} <small>({business?.businessName})</small></h2>
            <div className="Overview__section g_box space10">
                <div className="Overview__section__group">
                    Please choose the software product you would like to download, purchase or renew!
                </div>
            </div>
            
            <div className="Overview__packages">
                {!Packages?.length ? <Loader loading={true} msg="Please wait loading packages.." /> : Packages.map((packageX, index) => <Link key={index} to={`${Pages.PACKAGES}${packageX.link}`} className="Overview__packages__package g_box g_button">
                    <div className="Overview__packages__package__icon"><Icon icon={packageX.icon} /></div>
                    <div className="Overview__packages__package__title">{packageX.title}</div>
                    <div className="Overview__packages__package__summary">{packageX.summary}</div>
                    {hasPackage(packageX) && <div className="Overview__packages__package__entitled">Entitled</div>}
                </Link>)}
            </div>

            <div className="Overview__section g_box space10">
                <div className="Overview__section__group">
                    <span className="g_box_note">{(totalSubscriptions) ? `You have "${totalSubscriptions}" valid subscriptions.` : <i className="g_red"><b>Note:</b> You haven't subscribed or purchased any ebmbook software yet!</i>}</span>
                </div>
                
                <div className="Overview__section__group space10">
                    <span className="Overview__section__group__title">Business Nature</span>
                    <span className="Overview__section__group__value">{business?.businessType}</span>
                </div>

                <div className="Overview__section__group space10 Overview__section__groupbold">
                    <span className="Overview__section__group__title">{totalSubscriptions ? "Your License Key" : "Your demo license key is: "}</span>
                    <CopyToClipboard content={(business?.licenseKey) ? business?.licenseKey : "N/A - Some license keys are available on request only!"} />
                </div>
                <Warning classNames="small no_bg" msg="Note: License key is only valid for the software/apps you have subscribed or purchased!" reset={() => {}} />
                
            </div>
        </div>
    )
}

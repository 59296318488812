import React, { useEffect, useMemo, useState } from "react";
import { RequestType } from "../../../types";
import Button from "../../../components/Button/Button";
import Selection from "../../../components/Selection/Selection";
import TextBox from "../../../components/TextBox/TextBox";
import Warning from "../../../components/Warning/Warning";
import { Icons } from "../../../config/icons";
import { getCountriesSupported } from "../../../lib/countries";
import { message } from "../../../messages";
import { SalesEstimatorApiURL } from "../../../api/Endpoints";

import "./Estimator.scss";
import Loader from "../../../components/Loader/Loader";
import { sendRequest } from "../../../api/SendRequest";
import { useCategory } from "src/hooks/useCategory";


export default function Estimator() {
    const [country, selectCountry] = useState("");
    const [bsr, setBsr] = useState<number>();
    const [bsrSales, setSales] = useState("");
    const [category, selectCategory] = useState("");
    const [ loading, setLoading ] = useState(false);
    const [ isNotReady, setNotReady ] = useState(true);
    const [error, setError] = useState("");

    const countries = useMemo(() => getCountriesSupported().map((countryX) => {return {name: countryX.name}}), []);
    
    // load all the categories    
    const {loadingCats, countryCategories, countryCategoriesDetail} = useCategory(country);

    useEffect(() => {
        if (bsr && category && country) {
            setNotReady(false);//show clickable button
        } else {
            setNotReady(true);//not clickable button
        }
    }, [bsr, category, country])
    
    const estimate = () =>{
        if (!bsr || !category || !country) {
            setError("Please select the options!");
        }else if (!countryCategoriesDetail) {
            setError(`Problem in loading ${country}'s categories. Please reselect.`);
        }else { 
            
            const catSelected = countryCategoriesDetail.find((cat) => cat.name === category);
            if (!catSelected) {
                setError(`Selected category not found - might be removed.`);
            } else {
                setLoading(true);
                console.info(`Start estimation on ${bsr} BSR..`);
                // send data to API
                sendRequest({api: SalesEstimatorApiURL, params: {bsr, country: catSelected.country, amzCatId: catSelected.amzCatId}, rtype: RequestType.POST}, message.ErrorOther).then((res) => {
                    console.log(`status (${res.status}) ${res.statusText}`);
                    if (res?.data?.status) {
                        setSales(res.data?.data?.monthlySalesEstimate);
                    }
                    setError(res?.data?.message || res.statusText);
                    setLoading(false);
                });
            }
        }
    }

    return (
        <div className="Estimator center-screen">
            <h1 className="Estimator__title ptitle">{message.EstimatorTitle}</h1>
            <p className="Estimator__subtitle psubtitle">{message.EstimatorInfo}</p>
            <form>
                {bsrSales && 
                <div className="Estimator__result">
                    {bsrSales}
                    <p>{message.EstimatorResultText}</p>
                </div>
                }
                <TextBox lable={"Best Seller's Rank (BSR)"} placeholder={"e.g. 100"} setValue={(event) => setBsr(event?.target?.value)} />
                <Selection pleaseSelect={message.EstimatorSelectCountry} hasIcon={true} lable="Amazon Market Place (Country)" list={countries} selected={country} setValue={(event) => selectCountry(event?.target?.value)}  />
                
                <Loader msg={"Loading categories"} loading={loadingCats} />

                {!countryCategories ? 
                <Warning msg={message.EstimatorErrorCat} reset={() => {}} /> : 
                <Selection pleaseSelect={message.EstimatorSelectCategory} lable="Amazon Product Category" list={countryCategories} selected={category} setValue={(event) => selectCategory(event?.target?.value)}  />
                }

                <Warning msg={error} reset={() => setError(error)} />
                <Button callback={() => estimate()} value={"Estimate Sales"} icon={Icons.dashboard} classNames="spaceT10" loading={loading} isNotReady={isNotReady} />
            </form>
        </div>
    )
}
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IPackage } from "src/interfaces";
import { RootState } from "src/store";
import { getAllPackages } from "src/store/reducers/PackageSlicer";

export default function usePackages() {
    const {loading, packages} = useSelector((state: RootState) => state.package);
    const [packageDetail, setSelectedPackage] = useState<IPackage>();
    const dispatch = useDispatch();
    
    useEffect(() => {
        // no need to populate data if it's still there
        if(packages?.length) return;

        dispatch(getAllPackages() as any);
    }, [packages, dispatch]);

    const loadPackage = (packageName: string) => {
        setSelectedPackage(packages.find((packageX) => packageX.name === packageName));
    }

    return {
        loading, packages, loadPackage, packageDetail
    } 

}
import React, { useState } from "react";
import { Icons } from "src/config/icons";
import '../TextBox/TextBox.scss';

export interface ITextBox {
    placeholder: string;
    lable?: string;
    value?: any;
    callback?: any; 
    name?: string;
    min?: number;
    max?: number;
    isPassword?: boolean;  
    className?: string;
    isNumber?: boolean;
    /** clear the textfeild */
    showClear?: boolean;
    /** show red line around the box if there is error */
    isError?: boolean;
    setValue: (event: any) => void;
}
export default function TextInput({lable, placeholder, value, min, max, isPassword, isNumber, className,setValue, isError, showClear}: ITextBox){
    const [showPassword, setShowPassword] = useState(false);
    const clearBtn = () => {
        console.log("clicked")
        setValue({target: {value: ""}});
    }
    
    const inputField = () => {
        if (isNumber) {
            return (
                <input min={min} autoComplete="off" type="number" value={value} placeholder={placeholder} maxLength={max} onChange={(evnt) => setValue(evnt?.target?.value)} />
            )            
        }

        if (isPassword) {
            return (
                <>
                <input autoComplete="off" type={showPassword ? 'text' : 'password'} value={value} placeholder={placeholder} min={min} maxLength={max} onChange={(evnt) => setValue(evnt?.target?.value)} />
                <i className={!showPassword ? Icons.EyeClose : Icons.EyeOpen} onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)} />
                </>
            )
        }

        return (
            <input autoComplete="off" type="text" value={value} placeholder={placeholder} min={min} maxLength={max} onChange={(evnt) => setValue(evnt?.target?.value)} />
        )
    }

    return (
        <div className={`TextBox ${className} ${isError ? "g_error_line" : ""}`}>
            {lable ? (<label>{lable}</label>) : ""}
            {showClear && value ? (<span className="TextBox__clear" onClick={clearBtn}>Clear</span>) : ""}
            {inputField()}
        </div>
    )
}

import React, { useEffect, useState } from "react"
import Loader from "../Loader/Loader";

const context = require.context('src/assets/images/icons', false, /\.(png|jpe?g|svg)$/);
const ICONS = (context?.length) ? context.keys() : [];


interface IIcon {
    icon: string;
    title?: string;
}
export default function Icon({icon, title}: IIcon) {
    const [imageFound, setFound] = useState<string>("");

    useEffect(() => {
        if (ICONS.includes(icon)) {
            const imageX = require("../../assets/images/icons/"+icon.replace('./',''));
            //console.log("found: ", imageX)
            setFound(imageX);
        } else {
            console.log("Image not found: ", imageFound)
            setFound("");
        }

    }, [icon])

    const imgError = () => {
        console.log("issue with: ", imageFound);
    }

    return (
        <div className="Icon">
            {imageFound ? 
            <img src={imageFound} alt={title || ""} onError={() => imgError()} />
            :
            <Loader loading={true} msg="Loading.." />
            }
            
        </div>
    )
}
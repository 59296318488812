import { INavMenu } from "src/interfaces";
import { Pages } from "src/types";
import { Icons } from "./icons";

export const API_URL = process.env.REACT_APP_API;
export const DOMAIN_URL = process.env.REACT_APP_DOMAIN;
export const LOCAL_CACHE_USER = 'ebmb_user';
export const LOCAL_CACHE_MSG = 'ebmb_msg';
export const APP_NAME = process.env.REACT_APP_NAME;
export const PAYMENT_KEY = process.env.REACT_APP_PAYMENT_KEY;
export const WEBSITE_VERSION = process.env.REACT_APP_VERSION;
export const DEV_ENV = process.env.REACT_APP_DEV;

// Give 30 SECONDS gap between processing the same action again
export const SAME_ACTIONS_INTERVAL = 30;
// Default CURRENCY
export const CURRENCY_SYMBOL = "£";
export const CURRENCY = "GBP";

/**
 * Application base URL 
 */
export const getBaseURL = () => {
    return "/";
}

export const NAMES = {
    app: APP_NAME ? APP_NAME : 'ECommerce Central',
    company: 'Ebmbook'
}

export const NAV: INavMenu[] = [
    {
        title: 'Overview',
        icon: Icons.dashboard,
        link: Pages.OVERVIEW
    },
    {
        title: 'Account',
        icon: Icons.Settings,
        link: Pages.ACCOUNT
    },
    {
        title: 'Messages',
        icon: Icons.Message,
        link: Pages.MESSAGES
    }
]

export const SUBNAV_TOOLS: INavMenu[] = [
    {
        title: 'Research',
        icon: '',
        link: Pages.TOOLS_RESEARCH
    },
    {
        title: 'Sales Estimator',
        icon: '',
        link: Pages.TOOLS_EXTENSION
    },
    {
        title: 'Shortlist',
        icon: '',
        link: Pages.TOOLS_SHORTLIST
    },
    {
        title: 'Trends',
        icon: '',
        link: Pages.TOOLS_TRACK
    },
    {
        title: 'Extension',
        icon: '',
        link: Pages.TOOLS_EXTENSION
    }
]

export const NAV_LOGOUT: INavMenu = {
    title: 'Logout',
    icon: Icons.Logout,
    link: Pages.LOGOUT
}

export const GENDERS: string[] = [
    "Mr","Mrs","Miss"
]
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { NAV, NAV_LOGOUT } from "src/config";
import { RootState } from "src/store";
import './MainMenu.scss';

export default function MainMenu(){
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
    let links = NAV;
    if (isLoggedIn) {
        links = [...NAV, NAV_LOGOUT];      
    }

    const location = useLocation();

    const selectedPath = useMemo(() => links.find((a) => location.pathname.includes(a.link))?.link?.replace("/", ""), [links, location.pathname]);

    return (
        <div className="MainMenu">
            {
                links.map((menu, index) => (
                    <Link key={index} className={`MainMenu__link MainMenu__${menu.link?.replace("/", "")}-${selectedPath}`} to={menu.link}>
                        <i className={menu.icon} />
                        <span className="__title">{menu.title}</span>
                    </Link>
                ))
            }
        </div>
    )
}
import React from "react";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { ErrorBoundary } from "react-error-boundary";

interface IReactErrorBoundary {
    children: any;
    msg?: string;
    title?: string;
}
export default function ReactErrorBoundary({msg, title, children}: IReactErrorBoundary) {
    return (
        <ErrorBoundary
            FallbackComponent={() => ErrorPage({msg, title})}
            onError={(error, errorInfo) => {
                // log the error
				console.log("Error caught!");  
				console.error(error);  
				console.error(errorInfo);
            }}
            onReset={() => {
                // reloading the page to restore the initial state
                // of the current page
                console.log("reloading the page...");
                window.location.reload();

                // other reset logic...
            }}
        >
            {children}
        </ErrorBoundary>
    );
}
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ISettingsState {
    title: string;
}

const initialState: ISettingsState = {
    title: ""
}

const headerSlicer = createSlice({
    name: 'header',
    initialState,
    reducers: {
        headerTitle(state, action: PayloadAction<string>) {
            state.title = action.payload;
        }
    }
});

export const { headerTitle } = headerSlicer.actions;
export default headerSlicer.reducer;

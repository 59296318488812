import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserURL } from "../../../api/Endpoints";
import { sendRequest } from "../../../api/SendRequest";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/TextBox/TextBox";
import Warning from "../../../components/Warning/Warning";
import { Icons } from "../../../config/icons";
import { useAction } from "../../../hooks/useAction";
import { isPassword } from "../../../lib";
import { message } from "../../../messages";
import { update } from "../../../store/reducers/UserReducers";
import { ActionTypes, Pages, RequestType } from "../../../types";
import '../../Register/RegisterPage.scss';
import "./Password.scss";
import { action } from "src/store/reducers/ActionsReducers";

export default function Password() {
    const [ error, setError ] = useState("");
    const [ loading, setLoading ] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const {actionProcessed} = useAction(ActionTypes.PASSWORD_UPDATE);

    const [ newPasswordConfirm, setNewPasswordConfirm] = useState("");
    const [ newPassword, setNewPassword] = useState("");
    const [ oldPassword, setOldPassword] = useState("");

    const buttonClick = () => {
        setError(""); // reset error
        if (!isPassword(oldPassword)) {
            setError(message.PasswordWrong);
        } else if (!isPassword(newPassword)) {
            setError(message.PasswordRequiredNew);
        } else if (newPassword !== newPasswordConfirm) {
            setError(message.PasswordShouldMatch);
        } else {
            setLoading(true);
            console.info("Updating pass..");            
            sendRequest({api: UserURL+"update_password", params: {password: newPassword, oldPassword}, rtype: RequestType.PUT}, message.ErrorOther).then((res) => {
                setLoading(false);
                if (res.status === 200) {
                    dispatch(update(res.data?.data));
                    dispatch(action({name: ActionTypes.PASSWORD_UPDATE, value: "Your ebmbook central password has been updated."}));
                    navigate(Pages.ACCOUNT);
                } else {
                    setError(res.statusText);
                }
            });
        }
    }

    return (
        <div className="RegisterPage left-screen">
            <form>
                <TextBox className="TextBox2" isPassword={true} lable={"Current Password"} placeholder={"Current password here.."} value={oldPassword} setValue={(event) => setOldPassword(event?.target?.value)} />   
                <TextBox className="TextBox2" isPassword={true} lable={"New Password"} placeholder={"New password here.."} value={newPassword} setValue={(event) => setNewPassword(event?.target?.value)} />
                <TextBox className="TextBox2" isPassword={true} lable={"Confirm New Password"} placeholder={"New password here.."} value={newPasswordConfirm} setValue={(event) => setNewPasswordConfirm(event?.target?.value)} />
                
                <Warning msg={error} reset={() => setError("")} />
                {actionProcessed ? 
                    <Warning msg={`${message.MsgUpdateProcessed}`} reset={() => setError("")} />
                    : 
                    <Button callback={() => buttonClick()} value={"Change Password"} icon={Icons.Login} classNames="spaceT10" loading={loading}  />
                }
            </form>
        </div>
    )
}

import React from "react";
import { Icons } from "src/config/icons";
import { message } from "src/messages";
import './Button.scss';

export interface IButton {
    value: string;
    classNames?: string;
    icon?: string;
    loading?: boolean;
    isNotReady?: boolean;
    callback: () => void;
}

export default function Button({value, classNames, icon, loading, callback, isNotReady}: IButton) {

    const buttonHtml = (
        <>
        {isNotReady ? 
        <div className={`Button Button_notready ${classNames}`} >
            {icon ? (<i className={icon} />) : ''}
            <span>{value}</span>
        </div> :
        <div className={`Button ${classNames}`} onClick={() => callback()} >
            {icon ? (<i className={icon} />) : ''}
            <span>{value}</span>
        </div>
        }
        
        </>
    );

    const buttonOffHtml = (
        <div className={`Button Button_wait ${classNames}`}>
            <span>{message.MsgWait}</span>
            <i className={Icons.Spinner} />
        </div>
    )

    return loading ? buttonOffHtml : buttonHtml;     
}
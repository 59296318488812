import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PackagesURL } from "src/api/Endpoints";
import { sendRequest } from "src/api/SendRequest";
import { IPackage } from "src/interfaces";
import { RequestType } from "src/types";

interface IPackageSlicer {
    packages: IPackage [];
    loading: boolean;
    error?: string;
}

const Packages: IPackageSlicer = {
    packages: [],
    error: undefined,
    loading: true
}

export const getAllPackages = createAsyncThunk("packages/getAllPackages", async () => {
    
    const res = await sendRequest({api: PackagesURL, rtype: RequestType.GET});

    if (res.status === 200) {
        return res.data as IPackage [];

    } else {
        console.warn(`No packages: `, res);
        return [];
    }    
})

const PackageSlicer = createSlice({
    initialState: Packages,
    name: "Packages",
    reducers: {},
    extraReducers(builder) {
        builder.addCase(getAllPackages.fulfilled, (state, action) => {
            state.packages = action.payload;
        })
        builder.addCase(getAllPackages.rejected, (state) => {
            state.loading = false;
            state.error = "Problem getting packages!";
        })
        builder.addCase(getAllPackages.pending, (state) => {
            state.loading = true;
        })
    },
});

export default PackageSlicer.reducer;
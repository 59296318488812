import React from "react";
import { Icons } from "src/config/icons";
import './Loader.scss';

export interface IButton {
    msg: string;
    classNames?: string;
    loading: boolean;
}

export default function Loader({msg, loading, classNames}: IButton) {
   return (
    <>
        {loading &&    
        <div className={`Loader ${classNames}`}>
            <span>{msg}</span>
            <i className={Icons.Spinner} />
        </div>
        } 
    </>
   )
}
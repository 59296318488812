import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { UserURL } from "src/api/Endpoints";
import { sendRequest } from "src/api/SendRequest";
import Button from "src/components/Button/Button";
import Selection from "src/components/Selection/Selection";
import TextBox from "src/components/TextBox/TextBox";
import Warning from "src/components/Warning/Warning";
import { GENDERS } from "src/config";
import { Icons } from "src/config/icons";
import { useAction } from "src/hooks/useAction";
import { isEmail } from "src/lib";
import { getCountries } from "src/lib/countries";
import { message } from "src/messages";
import { RootState } from "src/store";
import { update } from "src/store/reducers/UserReducers";
import { ActionTypes, Pages, RequestType } from "src/types";
import '../../Register/RegisterPage.scss';
import { action } from "src/store/reducers/ActionsReducers";

export default function Profile() {
    const [ error, setError ] = useState("");
    const [ loading, setLoading ] = useState(false);

    const navigate = useNavigate();

    const dispatch = useDispatch();
    const {user} = useSelector((stat:RootState) => stat.user);
    const {actionProcessed} = useAction(ActionTypes.PROFILE_UPDATE);

    const [ fullname, setFullname] = useState(user?.fullname||"");
    const [ email, setEmail] = useState(user?.email||"");
    const [ title, setTitle] = useState(user?.title||"");
    const [ tel, setTel] = useState(user?.tel||"");
    const [ country, setCountry] = useState(user?.country||"");

    const buttonClick = () => {
        setError(""); // reset error
        if (!isEmail(email)) {
            setError(message.EmailRequired);
        } else if (fullname!?.length < 2 || fullname!?.length > 30) {
            setError(message.SignupNameRequired);
        } else if (tel && tel?.length > 20) {
            setError(message.TelephoneInvalid);
        } else {
            setLoading(true);
            console.info("Updating profile..");            
            sendRequest({api: UserURL+"profile", params: {email, fullname, tel, country, title}, rtype: RequestType.PUT}, message.ErrorOther).then((res) => {
                setLoading(false);
                console.log(`Update account status (${res.status}) ${res.statusText}`);
                if (res.status === 200) {
                    dispatch(update(res.data?.data));
                    dispatch(action({name: ActionTypes.PROFILE_UPDATE, value: "Your account profile has been updated successfully."}));
                    navigate(Pages.ACCOUNT);
                } else {
                    setError(res.statusText);
                }
            });
        }
    }

    return (
        <div className="RegisterPage left-screen">
            <form>
                <Selection lable={"Title"} list={GENDERS} selected={title} setValue={(event) => setTitle(event?.target?.value)} />
                <TextBox lable={"Fullname"} placeholder={"Your name here.."} value={fullname} setValue={(event) => setFullname(event?.target?.value)} />
                <TextBox lable={"Email"} placeholder={"something@example.com.."} value={email} setValue={(event) => setEmail(event?.target?.value)} />   
                <TextBox lable={"Telephone"} placeholder={"+44 777.."} value={tel} setValue={(event) => setTel(event?.target?.value)} max={20} />  
                <Selection lable={"Country"} list={getCountries()} selected={country} setValue={(event) => setCountry(event?.target?.value)} />

                <Warning msg={error} reset={() => setError("")} />
                {actionProcessed ? 
                    <Warning msg={`${message.MsgUpdateProcessed}`} reset={() => setError("")} />
                    : 
                    <Button callback={() => buttonClick()} value={"Update"} icon={Icons.Login} classNames="spaceT10" loading={loading}  />
                }
            </form>
        </div>
    )
}

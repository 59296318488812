import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ResetURL } from "src/api/Endpoints";
import { sendRequest } from "src/api/SendRequest";
import Button from "src/components/Button/Button";
import TextBox from "src/components/TextBox/TextBox";
import Warning from "src/components/Warning/Warning";
import { Icons } from "src/config/icons";
import { isEmail } from "src/lib";
import { message } from "src/messages";
import { Links, Pages, RequestType } from "../../types/index";
import './Reset.scss';
import { useHeader } from "src/hooks/useHeader";

export default function Reset() {
    // set page title in header
    useHeader({title: "Reset Password"});
    const [ email, setEmail ] = useState("");
    const [ error, setError ] = useState("");
    const [ isResetSent, setResetSent ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    const buttonClick = () => {
        // initial validation
        setError(""); // reset error
        if (!isEmail(email)) {
            setError(message.EmailRequired);
        } else {
            setLoading(true);
            // send data to API
            // setRequest({api: LoginURL, params: {email, password}, rtype: RequestType.POST});
            sendRequest({api: ResetURL, params: {email}, rtype: RequestType.POST, cacheOff: true}, message.ErrorOther).then((res) => {
                console.log(`status (${res.status}) ${res.statusText}`);
                if (res.status === 200) {
                    // reset link sent.
                    setResetSent(true);
                } else if (res.status === 429) {
                    setError("Your password reset request received already please wait few minutes!");
                    
                } else {
                    setError(res.statusText);
                }

                setLoading(false);
            });
        }
    }
    const changeEmail = (event: any) => {
        setEmail(event?.target?.value);
    }

    if (isResetSent) {

        return (
            <div className="Reset center-screen">
                <h1 className="Reset__title">Reset Password Sent</h1>
                <p className="Reset__subtitle">Reset your Ebmbook Central account password.</p>
                <form>
                    <div className="Reset__done">
                        <p>Okay, we just sent you a password reset email. </p>
                        <p>Didn't get it? Check your spam folder or please give it a few minutes.</p>
                    </div>
                    
                    <div className="Reset__bottomtext spaceT5">
                        <div>No email received after few minutes? <Link title="Contact Ebmbook Team" target="_blank" to={Links.CONTACT_US}>Contact us</Link>!</div>
                        <p>
                            Or, know your password?  
                            <Link to={Pages.LOGIN}>Login</Link>
                        </p>
                    </div>
                </form>
            </div>
        )        
    }

    return (
        <div className="Reset center-screen">
            <h1 className="Reset__title">Reset Password</h1>
            <p className="Reset__subtitle">Reset your Ebmbook Central account password only.</p>
            <form>
                <TextBox lable={"Registered Email"} placeholder={"account email here.."} value={email} setValue={changeEmail} />
                <Warning msg={error} reset={() => setError("")} />
                <Button callback={() => buttonClick()} value={"Get Reset Link"} icon={Icons.Login} classNames="spaceT10" loading={loading} />

                <div className="Reset__bottomtext spaceT5">
                    <p>
                        Or, know your password?  
                        <Link to={Pages.LOGIN}>Login</Link>
                    </p>
                </div>
            </form>
        </div>
    )
}

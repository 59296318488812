import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BusinessDetailsURL } from "src/api/Endpoints";
import { sendRequest } from "src/api/SendRequest";
import { message } from "src/messages";
import { RootState } from "src/store";
import { updateBusiness } from "src/store/reducers/UserReducers";
import { RequestType } from "src/types";

export default function useAccount() {    
    const {user} = useSelector((stat:RootState) => stat.user);
    const dispatch = useDispatch();

    const [loading, setLoading] = useState(false);

    const reloadBusiness = useCallback((cacheOff = false) => {
        setLoading(true);
        sendRequest({api: `${BusinessDetailsURL}`, rtype: RequestType.GET, cacheOff: cacheOff}, message.ErrorOther)
        .then((res) => {
            if (res.status === 200) {
                const businessX = res.data.data;
                console.log(`business loaded: `, businessX);
                dispatch(updateBusiness(businessX));

            } else {
                console.log(`No good business: `, res);
            }
        }).finally(() => {
            setLoading(false);
        }); 
    }, [dispatch]);

    useEffect(() => {
         // only load business details if not already loaded
        if (!user?.business) {
            reloadBusiness();
        }

    }, [reloadBusiness, user?.business]);
    

    return {
        user, reloadBusiness, loading, setLoading
    }
}

import React from "react";
import "./ErrorPage.scss";

interface IErrorPage {
    msg?: string;
    title?: string;
}

export default function ErrorPage({msg, title}: IErrorPage) {
    return (
        <div className="ErrorPage center-screen">
            <div className="ErrorPage__title">{title ? title : "Oops!"}</div>
            <div className="ErrorPage__msg">{msg ? msg : "Something went wrong..."}</div>
        </div>
    );
}

import { API_URL } from 'src/config';

export const LoginURL = API_URL + "/user/login";
export const SignupURL = API_URL + "/user/signup";
export const ResetURL = API_URL + "/user/reset_password";
export const ResetConfirmURL = API_URL + "/user/confirm_reset_password";
export const UserURL = API_URL + "/user/";
export const BusinessDetailsURL = API_URL + "/user/get_business";
export const SalesEstimatorApiURL = API_URL + "/amazon/sales_estimation_by_category";
/**
 * Endpoint URL for the root categories only.
 */
export const CategoriesApiURL = API_URL + "/amazon/categories";
export const SearchFilterApiURL = API_URL + "/amazon/search_filter";

export const PaymentAPI = API_URL + "/payment";
export const PaymentInit = API_URL + "/payment/payment_init";
export const PaymentStatusURL = API_URL + "/payment/payment_status";
export const PackagesURL = API_URL + "/packages";

import IMessages from "./IMessages";

export const english: IMessages = {
    InvalidBrowserTitle: "Invalid Browser",
    InvalidBrowserInfo: "Unsupported browser detected, please install one of the recent browser from the list.",
    LoginPageTitle: "Login",
    LoginPageText: "Please login to your account!",
    EmailRequired: "Valid email required!",
    PasswordRequired: "Password should be 5 to 20 characters, at least one number, uppercase and lowercase letters!",
    LoginPageTextNoAccount: "Not having account?",
    Register: "Register",
    Login: "Login",
    LoginAlreadyMember: "Already a member?",
    Signup: "Signup",
    SignupNameRequired: "Please provide your name!",
    ErrorSignup: "Sign up error!",
    ErrorOther: "Unexpected error occurred!",
    MsgWait: "Please wait..",
    EmailWrong: "A valid registered email needed!",
    PasswordWrong: "Have you forgotten your password? try to reset it.",
    EmailTaken: "Please login or try different email address!",
    MsgLoggedOut: "You have been logged out successfully.",
    MsgLoggedOutSession: "You been logged out due to your session being expired.  Please login.",
    MsgJustRegistered: "You have recently registered, please login with your registered email address.",
    Help: "Getting Started",
    ResetPassword: "Reset Password",
    Feedback: "App Feedback",
    Settings: "Account Settings",
    PersonalSettings: "Personal Settings",
    Overview: "Overview",
    Notification: "Notifications",
    Subscription: "Subscriptions",
    Profile: "Your Account Profile",
    Tools: "Amazon Tools",
    ProfileInfo: "Personal Information",
    MsgUpdateProcessed: "Update applied successfully!",
    TelephoneInvalid: "Invalid telephone number",
    PasswordRequiredNew: "New password should be 5 to 20 characters, at least one number, uppercase and lowercase letters",
    PasswordChange: "Change Your Password",
    PasswordShouldMatch: "Password should match.",
    Download: "Download Apps",
    MsgNotAvailable: "Not yet available!",
    Language: "Language",
    TxtHowToStart: "The Ebmbook Products Research Tool is very easy to use, see how to startup!",
    TxtHowToStartTitle: "Hey {$}! Welcome to {$}!",
    EstimatorTitle: "Amazon Sales Estimator",
    EstimatorInfo: "The Amazon Estimator Tool analyses monthly or daily number of sales for a product of specific Amazon category.",
    EstimatorErrorCat: "No categories found for this country.",
    EstimatorSelectCountry: "Please Select Marketplace..",
    EstimatorSelectCategory: "Select Amazon Category..",
    EstimatorResultText: "Estimated number of sales per month",
    EstimatorResearchTxtUseFilter: "Use the filters to tailor your search!"
}

import React, { useMemo, useRef, useState } from "react"
import { Icons } from "../../../config/icons"
import { getCountriesSupported } from "../../../lib/countries";
import List from "../../../components/List/List";
import Selection from "../../../components/Selection/Selection";
import Button from "../../../components/Button/Button";
import TextBox from "../../../components/TextBox/TextBox";
import CheckBox from "../../../components/CheckBox/CheckBox";
import { message } from "../../../messages";

import "./Research.scss";
import { useCategory } from "src/hooks/useCategory";
import Warning from "src/components/Warning/Warning";
import Loader from "src/components/Loader/Loader";
import { SearchFilterApiURL } from "src/api/Endpoints";
import { sendRequest } from "src/api/SendRequest";
import { RequestType } from "src/types";
import { scrollToRef } from "src/lib";


export default function Research() {
    // const [isToggledFilters, setToggleFilter] = useState(false);

    const ref = useRef(null);

    const [country, selectCountry] = useState("");
    const [category, selectCategory] = useState("");

    const [bsrFrom, setBsrFrom] = useState<number>();
    const [bsrTo, setBsrTo] = useState<number>();

    const [conditionNew, setConditionNew] = useState<boolean>();
    const [conditionUsed, setConditionUsed] = useState<boolean>();

    const [brandName, setBrandName] = useState("");
    const [manufacturer, setManufacturer] = useState("");
    const [color, setColor] = useState("");
    const [asin, setAsin] = useState("");

    const [ratingFrom, setRatingFrom] = useState("");
    const [ratingTo, setRatingTo] = useState("");

    const [reviewsFrom, setReviewsFrom] = useState("");
    const [reviewsTo, setReviewsTo] = useState("");
    
    const [buyBoxFba, setBuyBoxFBA] = useState<boolean>();
    const [buyBoxFbm, setBuyBoxFBM] = useState<boolean>();

    const [searchError, setSearchError] = useState("");
    const [errorProp, setErrorProp] = useState("");
    

    const countries = useMemo(() => getCountriesSupported().map((countryX) => {
        return {
            name: countryX.name,
            icon: countryX.icon
        }
    }), []);
    
    const [result, setResult] = useState();

    const {loadingCats, countryCategories, countryCategoriesDetail} = useCategory(country);

    const [ loadingResult, setLoadingResult ] = useState(false);

    const loading = loadingCats || loadingResult;

    /**
     * Clear all the filters
     */
    const clearAll = () => {
        selectCategory("");
        setBsrFrom(0);
        setBsrTo(0);
        setConditionNew(false);
        setConditionUsed(false);
        setBrandName("");
        setManufacturer("");
        setColor("");
        setAsin("");
        setRatingFrom("");
        setRatingTo("");
        setReviewsFrom("");
        setReviewsTo("");
        setBuyBoxFBA(false);
        setBuyBoxFBM(false);
        selectCountry("");
        setSearchError("");
        setErrorProp("");
    }

    const search = () => {
        console.log(country, category, bsrFrom, bsrTo, conditionNew, conditionUsed, brandName, manufacturer, buyBoxFba, buyBoxFbm);
        // reset previous errors
        setSearchError("");
        setErrorProp("");
        // check
        if (!country) {
            setSearchError("Please select Amazon country!");
        }else if (!category || !countryCategoriesDetail) {
            setSearchError(`Problem in loading ${country} sites categories. Please reselect.`);
        }else { 
            
            const catSelected = countryCategoriesDetail.find((cat) => cat.name === category);
            if (!catSelected) {
                setSearchError(`Selected category not found - might be removed.`);
            } else {
                setLoadingResult(true);   
                const params = {
                    country: catSelected.country, amzCatId: catSelected.amzCatId,
                    bsrFrom, bsrTo, conditionNew, conditionUsed, brandName, manufacturer, color, asin, buyBoxFba, buyBoxFbm, ratingFrom, ratingTo, reviewsFrom, reviewsTo
                }
                console.log("search started...", params); 
                // send data to API
                sendRequest({api: SearchFilterApiURL, params: params, rtype: RequestType.POST}, message.ErrorOther).then((res) => {
                    console.log(`------------- da: `, res);
                    if (res?.data?.status) {
                        scrollToRef(ref);
                        setResult(res.data?.data);
                    } else {
                        setSearchError(res.statusText);
                        setErrorProp(res.data?.prop);
                    }
                    setLoadingResult(false);
                });
            }
        }
    }

    return (
        <div className="Research">
            <div className="Research__filters">
                
                {/* {isMobile() && <Button callback={() => setToggleFilter(isToggledFilters ? false : true)} value={"Filters"} icon={Icons.Filter} classNames="Research__filters__toggle g_button" />}
                 */}

                <div className="Research__filters__list">
                    <div className="Research__filters__top">
                        <span className="Research__filters__top__filter">Filters</span>
                        <Button callback={() => clearAll()} value={"Clear All"} icon={Icons.Filter} classNames="Research__filters__top__clear g_button_dim" />
                    </div>
                    <div className="line" />
                    <div className="line" />                     
                    <div className="Research__filters__group">
                        <h3>Select Amazon Site</h3>                        
                        <List className="countries" list={countries} selected={country} setValue={(countryX) => selectCountry(countryX)}  />
                    </div>
                    {country && 
                    <>
                        <div className="line" />   
                        <div className="Research__filters__group">
                            <h3>Product Category</h3>                             
                            <Loader msg={"Loading categories"} loading={loadingCats} />
                            {!countryCategories ? 
                            <Warning msg={message.EstimatorErrorCat} reset={() => {}} /> : 
                            <Selection pleaseSelect={message.EstimatorSelectCategory} list={countryCategories} selected={category} setValue={(event) => selectCategory(event?.target?.value)}  />
                            }
                        </div>

                        <div className="line" />
                        <div className="Research__filters__group">
                            <h3>#Sales Rank "BSR"</h3>
                            <TextBox isError={errorProp === "bsrFrom"} className="group_item" lable={"From"} placeholder={"e.g. 100"} setValue={(event) => setBsrFrom(event?.target?.value)} value={bsrFrom} />
                            <TextBox isError={errorProp === "bsrTo"} className="group_item" lable={"To"} placeholder={"e.g. 200"} setValue={(event) => setBsrTo(event?.target?.value)} value={bsrTo} />
                        </div>
                        <div className="line" />
                        <div className="Research__filters__group">
                            <h3>Products Condition</h3>
                            <CheckBox lable={"New"} toggle={(isNew) => setConditionNew(isNew)} />
                            <CheckBox lable={"Used & Refurbished"} toggle={(isUsed) => setConditionUsed(isUsed)} />
                        </div>
                        <div className="line" />
                        <div className="Research__filters__group">
                            <h3>Other Details</h3>
                            <TextBox className="group_item" lable={"Brand Name"} placeholder={"e.g. Apple"} setValue={(event) => setBrandName(event?.target?.value)} value={brandName} />
                            <TextBox className="group_item" lable={"Manufacturer"} placeholder={"e.g. ABC"} setValue={(event) => setManufacturer(event?.target?.value)} value={manufacturer} />
                            <TextBox className="group_item" lable={"Color"} placeholder={"e.g. Black"} setValue={(event) => setColor(event?.target?.value)} value={color} />
                            <TextBox className="group_item" lable={"ASIN"} placeholder={"e.g. B0B8N.."} setValue={(event) => setAsin(event?.target?.value)} value={asin} />
                        </div>
                        <div className="line" />
                        <div className="Research__filters__group">
                            <h3>Ratings</h3>
                            <TextBox isError={errorProp === "ratingFrom"} className="group_item" lable={"From"} placeholder={"e.g. 3"} setValue={(event) => setRatingFrom(event?.target?.value)} value={ratingFrom} />
                            <TextBox isError={errorProp === "ratingTo"} className="group_item" lable={"To"} placeholder={"e.g. 5"} setValue={(event) => setRatingTo(event?.target?.value)} value={ratingTo} />
                        </div>
                        <div className="line" />
                        <div className="Research__filters__group">
                            <h3>Reviews Count</h3>
                            <TextBox isError={errorProp === "reviewsFrom"} className="group_item" lable={"From"} placeholder={"e.g. 100"} setValue={(event) => setReviewsFrom(event?.target?.value)} value={reviewsFrom} />
                            <TextBox isError={errorProp === "reviewsTo"} className="group_item" lable={"To"} placeholder={"e.g. 500"} setValue={(event) => setReviewsTo(event?.target?.value)} value={reviewsTo} />
                        </div>
                        <div className="line" />
                        <div className="Research__filters__group">
                            <h3>When Seller's Buy Box is</h3>
                            <CheckBox lable={"FBA"} toggle={(val) => setBuyBoxFBA(val)} />
                            <CheckBox lable={"FBM"} toggle={(val) => setBuyBoxFBM(val)} />
                        </div>
                        <div className="line" />
                        <Warning msg={searchError} reset={() => {}} />
                        <div className="line" />
                        <Button loading={loading} callback={() => search()} value={"Run Search"} icon={Icons.Search} classNames="Research__filters__group_btn g_button blinking" />                
                    </>
                    }
                </div>
                {/* } */}
            </div>
            <div className="Research__results" ref={ref}>
                {!result && <div className="center-screen font-large">{message.EstimatorResearchTxtUseFilter}</div>}                
            </div>
        </div>
    )
}
import React, { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { SignupURL } from "src/api/Endpoints";
import { sendRequest } from "src/api/SendRequest";
import Button from "src/components/Button/Button";
import Warning from "src/components/Warning/Warning";
import { Icons } from "src/config/icons";
import { isEmail, isPassword } from "src/lib";
import { message } from "src/messages";
import { RootState } from "src/store";
import { ActionTypes, BusinessType, Pages, RequestType, businessTypes } from "../../types/index";
import './RegisterPage.scss';
import TextInput from "src/components/TextInput/TextInput";
import Selection from "src/components/Selection/Selection";
import { action } from "src/store/reducers/ActionsReducers";
import { useHeader } from "src/hooks/useHeader";

export default function RegisterPage() {
    useHeader({title: "Signup New Account"});

    const [ fullname, setFullname ] = useState("");
    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ rePassword, setRePassword ] = useState("");
    const [ error, setError ] = useState("");
    const [ loading, setLoading ] = useState(false);

    const [errorProp, setErrorProp] = useState("");
    const [ businessType, setBusinessType ] = useState<BusinessType>();
    const [ company, setCompany ] = useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const selecter = useSelector((stat:RootState) => stat.user.actions);
    const registered = selecter?.find((actionX) => actionX.name === ActionTypes.REGISTERED);

    const buttonClick = () => {
        setError(""); // reset error
        if (!isEmail(email)) {
            setError(message.EmailRequired);
            setErrorProp("email");
        } else if (fullname?.length < 2 || fullname?.length > 30) {
            setError(message.SignupNameRequired);
            setErrorProp("fullname");
        } else if (company?.length < 2 || company?.length > 30) {
            setError("Please provide a valid business name!");
            setErrorProp("company");
        } else if (!businessType || !businessTypes.includes(businessType)) {
            setError("Select your main business type!");
            setErrorProp("businessType");
        } else if (!isPassword(password)) {
            setError(message.PasswordRequired);
            setErrorProp("password");
        } else if (password !== rePassword) {
            setError("Password should match!");
            setErrorProp("rePassword");
        } else {
            setLoading(true);
            console.info("Signup started..");            
            sendRequest({api: SignupURL, params: {email, password, fullname, businessType, company}, rtype: RequestType.POST, cacheOff: true}, message.ErrorOther).then((res) => {
                setLoading(false);
                console.log(`Signup status (${res.status}) ${res.statusText}`);
                if (res.status === 200) {
                    dispatch(action({name: ActionTypes.REGISTERED, value: "Account created successfully - please login!"}));
                    navigate(Pages.LOGIN);
                } else {
                    setError(res.statusText);
                }
            });
        }
    }

    const showPasswordFields = useMemo(() => {
        return (fullname && email && businessType && company) ? true : false;
    }, [fullname, email, businessType, company]);

    return (
        <div className="RegisterPage center-screen">
            <h1 className="RegisterPage__title">{message.Signup}</h1>
            <form>
                <TextInput max={25} isError={errorProp === "fullname"} lable={"Fullname"} placeholder={"Your name here.."} value={fullname} setValue={setFullname} />
                <TextInput max={250} isError={errorProp === "email"} lable={"Email"} placeholder={"something@example.com.."} value={email} setValue={setEmail} />
                <TextInput max={30} isError={errorProp === "company"} lable={"Business Name"} placeholder={"Business name.."} value={company} setValue={setCompany} />
                <Selection isError={errorProp === "businessType"} lable={"Business Type"} list={["Select business type..", ...businessTypes]} selected={businessType} setValue={(event) => setBusinessType(event?.target?.value)} />
                {(showPasswordFields) && <>
                    <TextInput isError={errorProp === "password"} lable={"Password"} placeholder={""} isPassword={true} setValue={setPassword} />
                    <TextInput isError={errorProp === "rePassword"} lable={"Re-Type Password"} placeholder={""} isPassword={true} setValue={setRePassword} />
                </>}

                <Warning msg={error} reset={() => setError("")} />
                {registered ? 
                    <Warning msg={`${message.MsgJustRegistered} (${registered.value})`} reset={() => setError("")} />
                    : 
                    <Button callback={() => buttonClick()} value={"Signup"} icon={Icons.Login} classNames="spaceT10" loading={loading}  />
                }
                                
                <div className="row">
                    <div className="row__column">
                        <small>By <strong>signing up</strong>, you agree to the
                        <ul>
                            <li><a href="https://ebmbook.com/terms-of-use/" target="_blank" rel="noreferrer">Ebmbook Terms of Use</a>,</li>
                            <li><a href="https://ebmbook.com/software-license-agreement/" target="_blank" rel="noreferrer"> Software License Terms</a></li>
                            <li>including <a href="https://ebmbook.com/privacy-policy/" target="_blank" rel="noreferrer"> Privacy & Cookie Use terms</a>.</li>
                        </ul>
                        </small>
                    </div>
                </div>

                <div className="RegisterPage__bottomtext spaceT5">
                    <p>
                        {message.LoginAlreadyMember}
                        <Link to={Pages.LOGIN}>{message.Login}</Link>
                    </p>
                </div>
            </form>
        </div>
    )
}

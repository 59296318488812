import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Icons } from 'src/config/icons';
import { strShort } from 'src/lib';
import { message } from 'src/messages';
import { RootState } from 'src/store';
import { Pages } from 'src/types';
import './Header.scss';
import Logo from 'src/components/logo/Logo';
import { getSubscriptionWarning } from 'src/lib/core';
import PopUp from 'src/components/PopUp/PopUp';

export default function Header() {    
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);
    const user = useSelector((state: RootState) => state.user.user)!;    
    const shortName = (user?.fullname) ? strShort(user.fullname, 5) : "";
    const businessName = (user?.business?.businessName) ? user.business?.businessName : "n/a";
    const tradingType = (user?.business?.businessType) ? user.business?.businessType : "n/a";
    
    const [showPopUp, setShowAlerts] = useState(false)

    const headerTitle = useSelector((state: RootState) => state.header.title);

    const { business } = user!;

    //console.log("---business details: ", business)

    const alerts = useMemo(() => {
        let msgs: string [] = []
        if (business?.entitlements?.length) {
            msgs = business.entitlements.map(e => getSubscriptionWarning(e)).filter(a => a);
        }
        if (!business?.isEmailConfirmed) {
            msgs.push(`Can you please confirm your email address - check your inbox or junk box of email "${user.email}"!`);
        }
        
        return msgs;

    }, [business?.entitlements, business?.isEmailConfirmed]);
    
    return (
        <div className="Header">
            {
                isLoggedIn ? 
                (
                    <>
                        <div className='Header__logo'><Logo showSmall={true} /></div>
                        {headerTitle && <div className='Header__title'>{headerTitle}</div>}
                        {alerts.length ? 
                            <div className='Header__alerts'>
                                <div className='Header__alerts__exist' onClick={() => setShowAlerts(true)}>
                                    <i className={Icons.Alert} /><span>{alerts.length}</span>
                                </div>
                                <PopUp showPopUp={showPopUp} cancel={() => setShowAlerts(false)}>
                                    <h2>Warning</h2>
                                    <ul>
                                        {alerts.map((al, key) => <li key={key}>{al}</li>)}
                                    </ul>
                                </PopUp>
                            </div> 
                            : 
                            <div className='Header__alerts__notexist'><i className={Icons.AlertNo} /></div>
                        }
                        <div className='Header__status Header__logged'>{shortName} <i className={Icons.Account} />
                            <div className='Header__logged__open'>
                                <div><strong>Hello</strong> {user?.fullname}</div>
                                <div>Business Name: {businessName}</div>
                                <div>Business Type: {tradingType}</div>
                                <Link className='Header__logged__open__logout' to={Pages.LOGOUT}>
                                    Logout
                                </Link>
                            </div>
                        </div>
                    </>
                ) 
                : 
                (<div className='Header__status Header__notlogged'><Link to={Pages.LOGIN}><i className={Icons.Account} /> {message.Login}</Link></div>)
            }
        </div>
    )
}
import React from 'react';
import { INavMenu } from 'src/interfaces';
import './SubMenu.scss'

export interface ISubMenu {
    tabs: INavMenu[];
    selectedTab: string;
    setTab: (title: string) => void; 
}

export default function SubMenu({tabs, selectedTab, setTab}: ISubMenu) {
    return (
        <ul className="SubMenu">
        {
            tabs.map((tab, index) => (
                <li key={index} className={`SubMenu__tab ${selectedTab === tab?.title ? 'SubMenu__selected' : ''}`} onClick={() => setTab(tab.title)}>
                    <span className="__title">{tab.title}</span>
                </li>
            ))
        }
        </ul>
    )
}
export const Icons = {
    Account: 'fa fa-address-card-o',
    Alert: 'fa fa-bell',
    AlertNo: 'fa fa-bell-slash-o',
    AppIos: 'icon_e icon-app-store',
    AppAndroid: 'icon_e icon-android-store',
    AmazonTools: 'fa fa-line-chart',
    ArrowDown: 'fa fa-angle-down',
    ArrowUp: 'fa fa-angle-up',
    ArrowRight: 'fa fa-angle-right',
    ArrowLeft: 'fa fa-angle-left',
    dashboard: 'fa fa-dashboard',
    Download: 'fa fa-cloud-download',
    Chat: 'fa fa-comment-dots',
    Edit: 'fa fa-edit',
    EyeClose: 'fa fa-eye-slash',
    EyeOpen: 'fa fa-eye',
    Feedback: 'fa fa-rss',
    Filter: 'fa fa-sliders',
    Help: 'fa fa-question-circle',
    Inventory: 'fa fa-cubes',
    Login: 'fa fa-unlock-alt',
    Logout: 'fa fa-sign-out',
    Ok: 'fa fa-check',
    Message: 'fa fa-send-o',
    Packages: 'fa fa-archive',
    Person: 'fa fa-user',
    Password: 'fa fa-key',
    Search: 'fa fa-search',
    Settings: 'fa fa-gears',
    Spinner: 'fa fa-spinner fa-spin',
    Subscription: 'fa fa-graduation-cap',
    Undo: 'fa fa-undo',
    Warning: 'fa fa-exclamation-circle'
}
import * as React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
//import Footer from './Footer/Footer';
import Header from './Header/Header';
import Menu from './Menu/Menu';
import './index.scss';
import { useEffect } from 'react';
import { loadUserTheme } from 'src/store/cache';
import AutoPopupMessage from 'src/components/AutoPopUp/AutoPopUp';

export default function MainLayout({children}: {children: any}) {    
    // used for most recent update which might not be saved yet
    const theme = useSelector((state: RootState) => state.setting.theme);
    // get this user's theme already saved
    const userObj = useSelector((state: RootState) => state.user);
    const user = userObj.user;    
    const isLoggedIn = userObj.isLoggedIn;

    useEffect(() => {        
        loadUserTheme({theme, user});   

    }, [theme, user]);

    const showLoggedHeader = () => {
        return (
            <>    
                <div className='MainLayout__container'>
                    <Header />
                    {children}             
                </div> 
                <Menu />            
            </>
        )        
    }

    return (
        <div className='MainLayout'>
            <AutoPopupMessage />
            {isLoggedIn ? showLoggedHeader() : <div className=''>{children}</div> }
        </div>
    )
}

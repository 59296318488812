import React, { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { APP_NAME } from "src/config";
import { Icons } from "src/config/icons";
import { useHeader } from "src/hooks/useHeader";
import { message, messageStr } from "src/messages";
import { RootState } from "src/store";
import "./index.scss"

export default function GettingStarted() {
    const [isSectionPassword, setSectionPassword] = useState(false);

    const { fullname } = useSelector((state: RootState) => state.user.user)!;

    const welcome = useMemo(() => messageStr(message.TxtHowToStartTitle, [fullname, APP_NAME]), [fullname]);

    // set page title in header
    useHeader({title: message.Settings});

    const showPasswordSection = () => {
        //setSectionFeedback(false);
        // close if already opened
        setSectionPassword((isSectionPassword) ? false : true);
    }

    const feedbackSection = (
        isSectionPassword && 
        <div className="Settings__inner Settings__feedback">
            To be replaced with the right description along with other contents..
        </div>
    );

    return (
        <div className="Setup left-contents">
            <h2>{welcome}</h2>
            <p>{message.TxtHowToStart}</p>
            <div className="Setup__section">
                <h2 onClick={showPasswordSection}><i className={Icons.Password} />{message.ResetPassword}<i className={(isSectionPassword) ? Icons.ArrowDown : Icons.ArrowRight} /></h2>
                {feedbackSection}
            </div>
        </div>
    )
}

import * as React from 'react';
import { message } from '../../messages'
import './BrowserHandler.scss'

export default function BrowserHandler({children}: {children: any}) {
    const goodBrowser = 'reversed' in document.createElement('ol');
    if (!goodBrowser){
        return (
            <div className='BrowserHandler'>
                <h1>{message.InvalidBrowserTitle}</h1>
                <p>{message.InvalidBrowserInfo}</p>
                <ul className='BrowserHandler__options'>
                    <li>Google Chrome</li>
                    <li>FireFox</li>
                    <li>Microsoft Edge</li>
                </ul>
            </div>
        );
    }

    return children;
}

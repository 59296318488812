import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Config from './config'
import BrowserHandler from './containers/BrowserHandler/BrowserHandler'
import MainLayout from './layouts';
import Routes from './routes';
import { store } from './store';

export default function App() {
  return (
    <BrowserHandler>
      <Provider store={store}>
        <BrowserRouter basename={Config.getBaseURL()}>
          <MainLayout>
            <Routes />
          </MainLayout>
        </BrowserRouter>
      </Provider>
    </BrowserHandler>
  );
}

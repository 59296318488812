import React from "react";

import "./ConfirmWindow.scss";

interface IConfirmWindow {
    title: string;
    msg: string;
    okTxt?: string;
    noTxt?: string;
    confirm: () => any;
    cancel: () => any;
}
export default function ConfirmWindow ({title, msg, confirm, cancel, okTxt, noTxt}: IConfirmWindow) {    
    return (
        <div className='ConfirmWindow'>
            <h2>{title}</h2>
            <p>{msg}</p>
            <div className="ConfirmWindow__action">
                <span className="g_button ConfirmWindow__action__ok" onClick={confirm}>{okTxt ? okTxt : "Confirm"}</span>
                <span className="g_button ConfirmWindow__action__cancel" onClick={cancel}>{noTxt ? noTxt : "Cancel"}</span>
            </div>
        </div>
    )
}
import React from "react";
import { NAMES } from "src/config";
import logo from '../../assets/images/logo.png';
import smallLogo from '../../assets/images/logo-icon.png';
import './Logo.scss';

interface ILogo {
    showSmall?: boolean;
}
export default function Logo({showSmall}: ILogo){
    if (showSmall) {
        return (
            <div className="Logo-small">
                <img src={smallLogo} alt={NAMES.app} />
            </div>
        )        
    }

    return (
        <div className="Logo">
            <img src={logo} alt={NAMES.app} />
        </div>
    )
}

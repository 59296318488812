import { createSlice, PayloadAction } from '@reduxjs/toolkit';
//, createReducer, createSelector, createAction, createAsyncThunk 

interface IExampleState {
    value: number;
    title: string;
}

const initialState: IExampleState = {
    value: 1,
    title: 'NONE'
}

const exampleSlicer = createSlice({
    name: 'counter',
    initialState,
    reducers: {
        increment(state) {
            state.value++;
            console.log('increment called: ', state);
        },
        title(state, action: PayloadAction<string>) {
            state.title = action.payload;
        }
    }
});

export const { increment, title } = exampleSlicer.actions;
export default exampleSlicer.reducer;

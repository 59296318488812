import React from "react";
import './CheckBox.scss';

export interface ICheckBox {
    lable: string;
    classNames?: string;
    autoChecked?: boolean;
    toggle: (hidePassword: boolean) => void;
}

export default function CheckBox({classNames, toggle, lable, autoChecked}: ICheckBox) {
    return (
        <div className={`CheckBox ${classNames}`}>
            <label>{lable}</label>
            <input checked={autoChecked} type="checkbox" onChange={(event) => toggle(event.currentTarget.checked)} />
        </div>
    )
}
import axios, { AxiosRequestConfig } from 'axios';
import { API_URL, LOCAL_CACHE_USER } from 'src/config';
import { IRequest, IResponse, IUser } from 'src/interfaces';
import { getError, hashArgs } from 'src/lib';
import { codeMessage, message } from 'src/messages';
import { CacheMemo, getUserToken, Message, MessageTypes } from 'src/store/cache';
import { Pages, RequestType } from 'src/types';

const URL = API_URL + "/user/";
export default class AuthService {
    static signup ({email, fullname, password}: IUser) {
        return axios.post(URL + "signup", {fullname,email,password}); 
    }
    static async login ({email, password}: IUser) {
        // cache the same result to avoid bambarding the backend 
        const response = await axios.post(URL + "login", { email, password });
        if (response?.data?.accessToken) {
            localStorage.setItem(LOCAL_CACHE_USER, JSON.stringify(response.data));
        }
        return response?.data;
    }
    static logout (){
        CacheMemo.clear();
        localStorage.removeItem(LOCAL_CACHE_USER);
    }   
}
/**
 * @note in request type GET the params aren't used instate should be added to api url as query or params such as ../1234 or ../?x=1235
 */
export const sendRequest = async ({api, rtype, params, cacheOff, forceLogoutOff, tempToken}: IRequest, defaultError = ""): Promise<IResponse> => {
    const cacheKey = hashArgs(api, params);
    
    let cachedData: IResponse = CacheMemo.get(cacheKey);
    if(cachedData && !cacheOff){
      console.log("cache data: ", cachedData);
      return cachedData;
    }
    
    try {
      const token = (tempToken) ? tempToken : getUserToken();
      console.log(`access: ${api} with token: `, token);

      const config: AxiosRequestConfig = {
        headers: {
          Authorization: "Bearer " + token,
          Sender: "ebmb-web"
        }
      };

      let result: any = null;

      if (rtype === RequestType.GET) {
        result = await axios.get(api!, config);

      } else if (rtype === RequestType.POST) {
        result = await axios.post(api!, params, config);

      } else if (rtype === RequestType.PUT) {
        result = await axios.put(api!, params, config);
      }

      if (result) {
        const { data, status, statusText } = result;   
        cachedData = {data, status, statusText}; 
        if (!cacheOff) {
          CacheMemo.set(cacheKey, cachedData);
          console.log("cache processed:", cachedData);          
        }
        return cachedData;        
      }

      return {
        status: 500,
        statusText: "Invalid request type"
      }
      
    } catch (err) {
      console.log('error is: ', err);
      const errorMsg = getError(err);
         
      // skip network error caching
      if ("ERR_NETWORK" === err?.code) {
        return {status: 599, statusText: err.message || defaultError};
      }
         
      if (!forceLogoutOff) {
        if (err?.response?.status === 401 && [codeMessage.AUTH_INVALID, codeMessage.AUTH_REQUIRED, "Unauthorized"].includes(err?.response?.statusText)) {
          // invalid token, log user out
          console.log("invalid token - log user out");
          Message.set({name: MessageTypes.LOGOUT, value: message.MsgLoggedOutSession});
          CacheMemo.clear();
          window.location.href = Pages.LOGOUT;
        }        
      }

      // cache our own known errors
      cachedData = {status: 501, statusText: defaultError}
      if (errorMsg) {
        cachedData = {status: err?.response?.status, statusText: errorMsg, data: err?.response?.data};
        CacheMemo.set(cacheKey, cachedData);        
      }

      return cachedData;
    } 
    
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Theme } from 'src/types';

interface ISettingsState {
    theme: Theme | undefined;
}

const initialState: ISettingsState = {
    theme: undefined
}

const settingSlicer = createSlice({
    name: 'setting',
    initialState,
    reducers: {
        theme(state, action: PayloadAction<Theme>) {
            state.theme = action.payload;
            console.log("theme selected: ", state.theme);
        }
    }
});

export const { theme } = settingSlicer.actions;
export default settingSlicer.reducer;

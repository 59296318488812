import React from 'react';
import { useSelector } from 'react-redux';
import { Route, Routes } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { RootState } from 'src/store';
import ExamplePage      from './Example/ExamplePage';
import LoginPage        from './Login/LoginPage';
import LogoutPage from './Logout/LogoutPage';
import RegisterPage from './Register/RegisterPage';
import { Pages } from 'src/types';
import Settings from './Settings/index';
import GettingStarted from './GettingStarted';
import Tools from './Tools';
import Overview from './Overview/Overview';
import PackagesComponent from './Packages/Packages';
import PaymentStatus from './Packages/Payments/PaymentStatus';
import Messages from './Messages/Messages';
import Reset from './Reset/Reset';
import ResetConfirm from './ResetConfirm/ResetConfirm';

/**
 * User should be logged in to see the other pages otherwise disable the routing of the other pages and force user to the login page.
 */
export default function Router() {
    const isLoggedIn = useSelector((state: RootState) => state.user.isLoggedIn);

    return (
        <CSSTransition timeout={300}>
            {isLoggedIn ? (                
                <Routes>
                    <Route index path={Pages.OVERVIEW} element={<Overview />} />
                    <Route path="/example" element={<ExamplePage />} />
                    <Route path={Pages.LOGOUT} element={<LogoutPage />} />
                    <Route path={Pages.ACCOUNT} element={<Settings />} />
                    <Route path={Pages.PACKAGES} element={<PackagesComponent />} />
                    <Route path={Pages.PAYMENT} element={<PaymentStatus />} />
                    <Route path={Pages.MESSAGES} element={<Messages />} />
                    <Route path={Pages.GETTING_STARTED} element={<GettingStarted />} />
                    <Route path={Pages.TOOLS} element={<Tools />} />
                    <Route path="*" element={<Overview />} />
                </Routes>
            ) : (          
                <Routes>
                    <Route index path={Pages.REGISTRATION} element={<RegisterPage />} />
                    <Route path={Pages.LOGIN} element={<LoginPage />} />
                    <Route path={Pages.RESET} element={<Reset />} />
                    <Route path={Pages.RESET_CONFIRM} element={<ResetConfirm />} />
                    <Route path={Pages.LOGOUT} element={<LogoutPage />} />
                    <Route path="*" element={<LoginPage />} />
                </Routes>
            )}
        </CSSTransition>
    )
}

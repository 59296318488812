import React, { useState } from "react";
import { SUBNAV_TOOLS } from "../../config";
import { useHeader } from "../../hooks/useHeader";
import SubMenu from "../../components/SubMenu/SubMenu";
import { message } from "../../messages";
import Estimator from "./Estimator/Estimator";
import Extension from "./Extension/Extension";
import "./index.scss"
import Research from "./Research/Research";
import Shortlist from "./Shortlist/Shortlist";
import Trends from "./Trends/Trends";

export default function Tools(){
    const [selectedTab, setTab] = useState(SUBNAV_TOOLS[0].title);

    // set page title in header
    useHeader({title: message.Tools});

    return (
        <div className="Tools left-contents">
            <SubMenu tabs={SUBNAV_TOOLS} selectedTab={selectedTab} setTab={(newTab) => setTab(newTab)} />            
            {/* <h2>hello</h2>
            <p>{message.TxtHowToStart}</p> */}

            <div className="Tools__section">
                {selectedTab === SUBNAV_TOOLS[0].title && <Research />}
                {selectedTab === SUBNAV_TOOLS[1].title && <Estimator />}
                {selectedTab === SUBNAV_TOOLS[2].title && <Shortlist />}
                {selectedTab === SUBNAV_TOOLS[3].title && <Trends />}
                {selectedTab === SUBNAV_TOOLS[4].title && <Extension />}
            </div>
        </div>
    )
}

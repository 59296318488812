import { useMemo } from "react";
import { IBusiness, IExtras, IPackage } from "src/interfaces";
import { toJSON } from "src/lib";
import { getSubscriptionWarning, isEntitlementExpired } from "src/lib/core";

export const useEntitlement = (business?: IBusiness, packageX?: IPackage) => {  

      
    /** Find this package in the subsriptions list */
    const entitlement = useMemo(() => {
        if (packageX && business?.entitlements) {
            // first find valid entitlement otherwise pick any            
            const entitlementX =  business.entitlements.find((x) => (x.packageName === packageX.name && x.status === "succeeded" && !isEntitlementExpired(x)));
            if (!entitlementX) {
                return business.entitlements.find((x) => x.packageName === packageX.name);
            }

            if (entitlementX.extras?.length) { 
                const extras = toJSON(toJSON(entitlementX.extras)) as IExtras[];
                return {...entitlementX, extras: extras};
            }

            return entitlementX;

        }
        return undefined;
    }, [packageX, business?.entitlements]);


    /**
     * The list of the valid entitlements.
     */
    const validEntitlements = useMemo(() => {
        if (business?.entitlements) {
            // first find valid entitlement otherwise pick any            
            const entitlements =  business.entitlements.filter((x) => (x.status === "succeeded" && !isEntitlementExpired(x)));
            return entitlements;
        }
        return [];
    }, [business?.entitlements]);
    
    const entitlementWarningMsg = useMemo(() => {
        return getSubscriptionWarning(entitlement);

    }, [entitlement]);

    /**
     * Is dueDate expired/old or invalid.
     */
    const isExpired = () => {
        if (!entitlement) {
            return true;
        }
        return isEntitlementExpired(entitlement);
    }

    /**
     * True if there is a valid entitlement/subscription.
     */
    const isValid = () => {
        if (isExpired()) {
            return false;
        } else if (entitlement?.status === "succeeded") {
            return true;
        }

        return false;
    }

    /**
     * True if the payment is still under process but not expired.
     * Customer can wait for the initiated payment to succeed or fail.
     */
    const isProcessing = () => {
        if (isExpired()) {
            return false;
        } else if (entitlement?.status === "processing") {
            return true;
        }

        return false;
    }

    /**
     * True if user is entitled to this selected package.
     */
    const hasPackage = (packageX: IPackage) => {
        return validEntitlements?.some((ent) => ent.packageName === packageX.name);
    }


    return {
        entitlement, isExpired, isValid, entitlementWarningMsg, validEntitlements, hasPackage, isProcessing
    }
}
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { message } from "src/messages";
import { Message } from "src/store/cache";
import { logout } from "src/store/reducers/UserReducers";
import { Pages } from "src/types";

export default function LogoutPage() {
    
    const dispatch = useDispatch();
    dispatch(logout());
    const navigate = useNavigate();
    const logoutMsg = Message.getLogoutMsg();
    const msg = (logoutMsg) ? logoutMsg.value : message.MsgLoggedOut;
    const delayMsg = (logoutMsg) ? 3000 : 2000;//give users enough time to read the message as it's slightly longer.

    useEffect(() => {
        const navigateAfterMsg = () => {
            setTimeout(() => {
                Message.remove();//remove any temp messages
                navigate(Pages.LOGIN);
            }, delayMsg);
        }
        navigateAfterMsg();

    }, [dispatch, navigate, delayMsg]);

    return (
        <div className="LoginPage center-screen">
            {msg}
        </div>
    )
}

import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SAME_ACTIONS_INTERVAL } from "src/config";
import { RootState } from "src/store";
import { ActionTypes } from "src/types";

export const useAction = (actionType: ActionTypes) => {
    const actions = useSelector((stat:RootState) => stat.user.actions);
    const [actionProcessed, setActionProcessed] = useState(false);
    const [actionValue, setValue] = useState();
    const getSecondsDifference = (selectedTime: number) => {        
        return (new Date().getTime() - selectedTime) / 1000;
    }

    // load recently processed action
    useEffect(() => {            
        const actionX = actions?.find((actionX) => actionX.name === actionType && getSecondsDifference(actionX.time!) <= SAME_ACTIONS_INTERVAL);
        if (actionX) {
            setActionProcessed(true);     
            setValue(actionX.value);         
        } else {
            setActionProcessed(false);     
            setValue(undefined); 
        }

    }, [actions, actionType]);

    return {actionProcessed, actionValue}
}

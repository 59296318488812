import React from "react";
import { Icons } from "src/config/icons";
import './Warning.scss';

export interface IWarning {
    msg: string;
    classNames?: string;
    reset: () => void;
}
export default function Warning({msg, reset, classNames}: IWarning){
    return (!msg ? null : 
        <div className={`Warning ${classNames}`} onClick={reset}>
            <i className={Icons.Warning} />
            <p>{msg}</p>
        </div>
    )
}

import React from "react";

import "./PopUp.scss";

interface IPopUp {
    cancel: () => any;
    showPopUp: boolean;
    children: any;
}
export default function PopUp ({showPopUp, cancel, children}: IPopUp) {   
    if (!showPopUp) {
        return <></>        
    }

    return (
        <div className='center-screen PopUp' onClick={cancel}>
            <div>
                {children}
            </div>
        </div>
    )
}
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { LoginURL } from "src/api/Endpoints";
import { sendRequest } from "src/api/SendRequest";
import Button from "src/components/Button/Button";
import TextBox from "src/components/TextBox/TextBox";
import Warning from "src/components/Warning/Warning";
import { Icons } from "src/config/icons";
import { isEmail, isPassword } from "src/lib";
import { message } from "src/messages";
import { login } from "src/store/reducers/UserReducers";
import { Pages, RequestType } from "../../types/index";
import './LoginPage.scss';
import { useHeader } from "src/hooks/useHeader";

export default function LoginPage() {
    useHeader({title: "Login"});

    const [ email, setEmail ] = useState("");
    const [ password, setPassword ] = useState("");
    const [ error, setError ] = useState("");
    const [ loading, setLoading ] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const buttonClick = () => {
        // initial validation
        setError(""); // reset error
        if (!isEmail(email)) {
            setError(message.EmailRequired);
        } else if (!isPassword(password)) {
            setError(message.PasswordRequired);
        } else {
            setLoading(true);
            console.info("Start login..");
            // send data to API
            // setRequest({api: LoginURL, params: {email, password}, rtype: RequestType.POST});
            sendRequest({api: LoginURL, params: {email, password}, rtype: RequestType.POST, cacheOff: true, forceLogoutOff: true}, message.ErrorOther).then((res) => {
                console.log(`Login (${res.status}) ${res.statusText}`, res);
                if (res.status === 200) {
                    // add user to the store and save the JWT token in the localstorage
                    // redirect user to the dashboard page
                    dispatch(login(res.data.data));
                    // for first time login goto the getting started page
                    navigate(Pages.OVERVIEW);
                    // otherwise goto dashboard page
                }
                setError(res.statusText);
                setLoading(false);
            });
        }
    }
    const changeEmail = (event: any) => {
        setEmail(event?.target?.value);
    }
    const changePassword = (event: any) => {
        setPassword(event?.target?.value);
    }

    return (
        <div className="LoginPage center-screen">
            <h1 className="LoginPage__title">{message.LoginPageTitle}</h1>
            <p className="LoginPage__subtitle">{message.LoginPageText}</p>
            <form>
                <TextBox lable={"Email"} placeholder={"something@example.com.."} value={email} setValue={changeEmail} />                
                <TextBox lable={"Password"} placeholder={""} isPassword={true} setValue={changePassword} />
                <Warning msg={error} reset={() => setError("")} />
                <Button callback={() => buttonClick()} value={"Login"} icon={Icons.Login} classNames="spaceT10" loading={loading} />
                <div className="LoginPage__bottomtext spaceT5">
                    <p>
                        {message.LoginPageTextNoAccount}
                        <Link to={Pages.REGISTRATION}>{message.Register}</Link>
                    </p>
                    <p>
                        Or, forgotten your password? 
                        <Link to={Pages.RESET}>Reset</Link>
                    </p>
                </div>
            </form>
        </div>
    )
}

import { LOCAL_CACHE_USER, LOCAL_CACHE_MSG } from 'src/config';
import { IUser } from 'src/interfaces';
import { toJSON } from 'src/lib';
//NOTE: In future we should only store uid and JWT in the localstorage. During initial app loading the JWT will be send to the server which will send back user info.
//We should also not just depend on the localstorage, this is to cover the case when cookies are disabled. For such problem we should have another solution too.

//In memory cache
var map = new Map();
export const CacheMemo = map;

export const getUser = () => {
    try {
        return JSON.parse(localStorage.getItem(LOCAL_CACHE_USER)!);
    } catch (error) {
        return null;
    }
}

/**
 * @warning this should be called only by initialUserState
 * @see initialUserState
 */
export const initUser = () => {
    // add here anything that to be loaded on init..    
    return getUser();
}

export const setUser = (user: IUser) => {
    localStorage.setItem(LOCAL_CACHE_USER, JSON.stringify(user));
}
/**
 * Load user theme.  The param theme is the priority otherwise use already stored theme.
 */
export const loadUserTheme = ({theme, user}: {theme?: any, user?: any}) => {
    if (theme) {
        document.body.className = theme;            
    } else if (user?.settings) {
        // It might be string as JSON, parse to json. In futher parsing should be done on the backend.
        const theTheme = (user.settings?.theme) ? user.settings?.theme : toJSON(user.settings)?.theme;
        document.body.className = theTheme;            
    }
}

export const removeUser = () => { 
    CacheMemo.clear();
    localStorage.removeItem(LOCAL_CACHE_USER);
}

export const getUserToken = () => {
    return getUser()?.token;
}

export enum MessageTypes {
    LOGOUT = "LOGOUT"
}
export interface IMessage{
    name: MessageTypes;
    value: string;
}
/**
 * Short message to be remembered even after refreshing the browser.
 */
export class Message {
    static set(msg: IMessage){
        localStorage.setItem(LOCAL_CACHE_MSG, JSON.stringify(msg));
    }
    static get(){
        try {
            return JSON.parse(localStorage.getItem(LOCAL_CACHE_MSG)!) as IMessage;
        } catch (error) {
            return null;
        }
    }
    /**
     * Get logout message otherwise null if not a valid logout message.
     */
    static getLogoutMsg(){
        const msg = this.get();
        return (msg?.name === MessageTypes.LOGOUT) ? msg : null;
    }
    static remove(){
        localStorage.removeItem(LOCAL_CACHE_MSG);
    }
}
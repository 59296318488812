import React, { useRef } from "react";
import './CopyToClipbaord.scss';

export interface ICopyToClipbaord {
    content: string;
    className?: string;
}
export default function CopyToClipbaord({content, className}: ICopyToClipbaord){
    const ref = useRef<HTMLSpanElement>(null);
    const copyTo = "Click to Copy";
    
    const copy = () => {
        navigator.clipboard.writeText(content);
        if (ref.current?.innerHTML) {
            ref.current.innerHTML = "Copied";
        }
    }

    const reset = () => {
        if (ref.current?.innerHTML) {
            ref.current.innerHTML = copyTo;
        }
    }

    return (
        <span onClick={copy} className={`Copy ${className}`} onMouseOut={reset}>
            <span className="Copy__tooltiptext" ref={ref} id="myTooltip">{copyTo}</span>
            {content}
        </span>
    )
}

import React from "react";
import { Icons } from "src/config/icons";
import './ButtonSubmit.scss';

export interface IButtonSubmit {
    value: string;
    classNames?: string;
    loading?: boolean;
}

export default function ButtonSubmit({value, classNames, loading}: IButtonSubmit) {

    return <button className={`ButtonSubmit g_button ${classNames} ${loading ? 'Button_notready Button_wait' : ''}`} disabled={loading} id="submit">
        {loading ? <i className={Icons.Spinner} /> : value}
    </button> 
}
import { english } from './english';
import IMessages from './IMessages';

// In future pick the correct language based on the user's settings.
export const message: IMessages = english;

interface IMessageCode {
    [key: string]: string;
}

export const codeMessage: IMessageCode = {
        "NOT_FOUND": message.EmailRequired,
        "EXCEPTION": message.ErrorOther,
        "WRONG_EMAIL": message.EmailWrong,
        "INVALID_PASSWORD": message.PasswordWrong,
        "EMAIL_EXIST": message.EmailTaken,
        "NOT_READY": "NOT_READY",
        "AUTH_REQUIRED": "Authentication required",
        "AUTH_INVALID": "Invalid Auth Token"
}

interface ILanguages {
    name: string;
    icon?: string;
}
export const Languages: ILanguages[] = [
    {name: "English"}
]
/**
 * Replace in the `inMsg` the word `{$}` with the params in order. 
 * @example replace inMsg value `hello {$}` with `hello Sailab` when params value is `["Sailab"]`
 * @param inMsg 
 * @param params 
 * @returns new msg
 */
export const messageStr = (inMsg: string, params: any[]) => {
    const SIGN = "{$}";
    let newMsg = inMsg;
    let count = 0;
    while (newMsg.includes(SIGN)) {
        newMsg = newMsg.replace(SIGN, params[count])
        if (count >= params.length) { // just to avoid unnecessary run
            break;            
        }
        count++;
    }

    return newMsg;
}
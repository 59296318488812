// make a hook to cache the query and response from the server 
// useRef to presist object between the renderers, if this solution doesn't work we will use map to cache the result
import axios from 'axios';
import { useEffect, useState } from 'react';
import { IRequest, IResponse } from 'src/interfaces';
import { getError, hashArgs } from 'src/lib';
import { message } from 'src/messages';
import { RequestType } from 'src/types';

const CACHE = {};
interface IUseApi {
  response?: IResponse;
  loading: boolean;
  error?: string;
}

/**
 * A hook that post/get data from API and presist them in memory locally.
 * @param api string the full endpoint url
 * @param params object holding all the parameters example {name: 'Sailab'}
 * @param defaultError string default error in case error from server isn't the same
 * @returns states {response, loading, error}
 */
export const useApi = (req: IRequest, defaultError = ""): IUseApi => {
    const [ response, setResponse ] = useState<IResponse>();
    const [ loading, setLoading ] = useState(false);
    const [ error, setError ] = useState("");
    const {api, rtype, params} = req;

    useEffect(() => {
      if (!api || !api.trim()) return;

      setLoading(true);
      setError(""); //reset error
      const cacheKey = hashArgs(api, params);
      let cachedData: IResponse = CACHE[cacheKey];
      if(cachedData){
        if (cachedData.statusText) {
          // show any cached error
          setError(cachedData.statusText);
        }
        setResponse(cachedData);
        setLoading(false);
        console.log("cache data: ", cachedData);
        return;
      }

      const renderData = async () => {
          try {
            if (rtype === RequestType.GET) {
              const {data, status, statusText}  = await axios.get(api, params);      
              cachedData = CACHE[cacheKey] = {data, status, statusText};
              console.log("get cache processed:", cachedData)
              setResponse(cachedData);

            } else if (rtype === RequestType.POST) {
              const {data, status, statusText} = await axios.post(api, params);              
              cachedData = CACHE[cacheKey] = {data, status, statusText};
              console.log("post cache processed:", cachedData)
              setResponse(cachedData);

            } else {
              setError(message.ErrorOther);
              console.log(`Invalid API request type ${rtype}`);

            }
          } catch (err) {
            console.log('error is: ', err);
            const errorMsg = getError(err);
            // cache errors too - could be our server messages
            if (errorMsg){
              console.log("cache error too:", cachedData);
              cachedData = CACHE[cacheKey] = {status: err?.response?.status, statusText: errorMsg};
              setResponse(cachedData);
            }
            setError(errorMsg || defaultError);

          } finally {            
            setLoading(false);

          }        
      }

      renderData();

    }, [api, rtype, params, defaultError]);

    return {response, loading, error}
  }

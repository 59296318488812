import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { APP_NAME } from "src/config";
import { headerTitle } from "src/store/reducers/headerSlicer";

export interface IHeader {
    title: string;
}
export const useHeader = ({title}: IHeader) => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(headerTitle(title));
        if (title) {
            document.title = (APP_NAME) ? (title + " - " + APP_NAME) : title;           
        } else {
            document.title = APP_NAME || "Ebmb"; 
        }
    }, [title, dispatch]);
}
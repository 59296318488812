import { configureStore } from '@reduxjs/toolkit';
import thunkMiddleware from 'redux-thunk';
import settings from 'src/store/reducers/settings';
import user from 'src/store/reducers/UserReducers';
import actionsReducers from './reducers/ActionsReducers';
import example from './reducers/example';
import headerSlicer from './reducers/headerSlicer';
import PackageSlicer from './reducers/PackageSlicer';
/**
 * The new version of redux is @reduxjs/toolkit to eliminate the "boilerplate" from hand-written Redux 
 */
export const store = configureStore({
    reducer: {
        example: example,
        setting: settings,
        header: headerSlicer,
        action: actionsReducers,
        user: user,
        package: PackageSlicer
    },    
   devTools: process.env.NODE_ENV !== 'production',   
   middleware: [thunkMiddleware]
});

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch;

import React, { useState } from "react";
import { Icons } from "src/config/icons";
import './TextBox.scss';

export interface ITextBox {
    placeholder: string;
    lable?: string;
    value?: any;
    callback?: any; 
    name?: string;
    min?: number;
    max?: number;
    isPassword?: boolean;  
    className?: string;
    /** show red line around the box if there is error */
    isError?: boolean;
    setValue: (event: any) => void;
}
export default function TextBox({lable, placeholder, value, min, max, isPassword, className,setValue, isError}: ITextBox){
    const [showPassword, setShowPassword] = useState(false);
    
    const inputField = () => {
        if (isPassword) {
            return (
                <>
                <input autoComplete="off" type={showPassword ? 'text' : 'password'} value={value} placeholder={placeholder} min={min} max={max} onChange={setValue} />
                <i className={!showPassword ? Icons.EyeClose : Icons.EyeOpen} onClick={() => showPassword ? setShowPassword(false) : setShowPassword(true)} />
                </>
            )
        }

        return (
            <input autoComplete="off" type="text" value={value} placeholder={placeholder} min={min} max={max} onChange={setValue} />
        )
    }

    return (
        <div className={`TextBox ${className} ${isError ? "g_error_line" : ""}`}>
            {lable ? (<label>{lable}</label>) : ""}
            {inputField()}
        </div>
    )
}

import React from "react";
//use for without hook - direct
import { useSelector, useDispatch } from 'react-redux'
import { increment } from '../../store/reducers/example';
import { RootState } from '../../store'
//for hook use only
import { useExampleAppSelector, useExampleAppDispatch } from '../../hooks/useExample';

export default function ExamplePage(){
    // use this without hook
    const value = useSelector((state: RootState) => state.example.value);
    const dispatch = useDispatch();

    // use this with hook
    const valueHook = useExampleAppSelector((state) => state.example.value);
    const dispatchHook = useExampleAppDispatch();

    return (
        <div className="NotFound">
            <p>This is example page!</p>
            <div>
                <button onClick={() => dispatch(increment())}>
                    Counter is: {value}
                </button>
                <button onClick={() => dispatchHook(increment())}>
                    Counter with Hook is: {valueHook}
                </button>
            </div>
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import Warning from "src/components/Warning/Warning";
import useAccount from "src/hooks/useAccount";
import { useHeader } from "src/hooks/useHeader";
import { IDownload, IPackage, IPackagePriceType } from "src/interfaces";
import { ActionTypes, Pages, RequestType } from "src/types";
// stripe modules
import {Elements} from '@stripe/react-stripe-js';
import {Stripe, loadStripe} from '@stripe/stripe-js';

import "./Packages.scss";
import Button from "src/components/Button/Button";
import { action } from "src/store/reducers/ActionsReducers";
import Loader from "src/components/Loader/Loader";
import CheckoutForm from "src/components/CheckoutForm/CheckoutForm";
import ReactErrorBoundary from "src/containers/ReactErrorBoundry/ReactErrorBoundry";
import usePackages from "src/hooks/usePackages";
import { convertStripeAmountToNormal, dateToStr } from "src/lib";
import CheckBox from "src/components/CheckBox/CheckBox";
import { useEntitlement } from "src/hooks/useEntitlement";
import CopyToClipbaord from "src/components/CopyToClipbaord/CopyToClipbaord";
import { Icons } from "src/config/icons";
import ConfirmWindow from "src/components/ConfirmWindow/ConfirmWindow";
import Icon from "src/components/Icon/Icon";
import { PAYMENT_KEY } from "src/config";
import { sendRequest } from "src/api/SendRequest";
import { PaymentAPI } from "src/api/Endpoints";

enum Tabs {
    DOWNLOAD = "download",
    PAY = "pay",
    UPGRADE = "upgrade",
    PURCHASE = "purchase",
    LICENSE = "license_detail",
    PROCESSING = "payment_processing"
}

const DefaultCurrency = "GBP";

export default function PackagesComponent() {
        
    const {user, reloadBusiness, loading, setLoading} = useAccount();
    const {packages: Packages} = usePackages();
    const { business } = user!;

    const dispatch = useDispatch();
    
    const [searchParams,] = useSearchParams();
    const app = searchParams.get("app");
    const tabAction = searchParams.get("action");
    const [packageX, setPackageX] = useState<IPackage>();
    // the chosen price out of the prices range
    const [selectedPackagePrice, setSelectedPackagePrice] = useState<IPackagePriceType>();
    const [selectedPackageReadyToPay, setSelectedPackageReadyToPay] = useState(false);
    // true if requires auto renew which will be entitlement otherwise just payment
    const [autoRenew, setAutoRenew] = useState(true);

    // set page title in header
    useHeader({title: (!packageX) ? "App Details" : `${packageX.title} Detail`});

    const [tab, setTab] = useState<Tabs>();
    const [criticalError, setCriticalError] = useState("");

    const [strip, setStripe] = useState<Promise<Stripe | null>>();

    // find this package in the subsriptions list
    const {entitlement, isValid, entitlementWarningMsg, isProcessing} = useEntitlement(business, packageX);

    // reset the payment which is under process
    const resetPaymentProcessing = () => {
        console.log("..Resetting payment", entitlement);
        if (!entitlement?.id) {
            return;
        }

        setLoading(true);
        // Should delete this record that is under process from central and crm        
        sendRequest({api: `${PaymentAPI}/processing_payment_cancel`, rtype: RequestType.POST, params: {order_id: entitlement?.id}}).then((res) => {
            if (res.status === 200) {
                // Then reload business entitlements from system
                reloadBusiness(true);

            } else {
                console.log(`No good business: `, res);
            }
        }).finally(() => {
            setLoading(false);
        });
    }
    
    // on start
    useEffect(() => {
        if (!business) { // only load business details if not already loaded
            reloadBusiness();          
        }

    });

    // once package loaded and app provided via URL
    useEffect(() => {
        if (app && Packages.length) {
            if (!PAYMENT_KEY) {
                setCriticalError("Oh, we had problem with loading the payment details.  Please try again later!");
                
            } else {
                const packageFound = Packages.find((x) => x.name === app);
                if (packageFound) {
                    setPackageX(packageFound);
                    // Load the strip setup once package found & avoid recreating the `Stripe` object on every render.
                    // and only load if there isn't any valid purchased subscription
                    //NOTE: remove replace the test key with prod in production
                    if (packageFound.canPurchase && !isValid()) { 
                        const stripePromise = loadStripe(PAYMENT_KEY);
                        setStripe(stripePromise);
            
                        // Purchase tab requested via URL 
                        if (tabAction === Tabs.PURCHASE) {
                            setTab(Tabs.PAY);
                        }                    
                    }
                    
                } else {
                    console.log("app not found", app);
                }
            }          
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [app, Packages, tabAction, PAYMENT_KEY]);


    // select default tab based on user's entitlement
    useEffect(() => {
        if (entitlement) { 
            if (isValid()) {
                setTab(Tabs.DOWNLOAD);
            } else if (isProcessing()) {
                setTab(Tabs.PROCESSING);
            } else {
                setTab(Tabs.UPGRADE);
            }
        } else {
            setTab(Tabs.PAY);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entitlement]);

    const [showConfirmDownload, setShowConfirmDownload] = useState<IDownload>();

    const downloadProduct = (download: IDownload) => {
        if (!download.link) {
            // link not available show message
            dispatch(action({name: ActionTypes.ALERT, value: `Download not yet available for ${packageX?.title} ${download.platform}!`}));
            
        } else {
            // download this product
            setShowConfirmDownload(download);
            return;
        }
    }

    const showSelectedTabContents = () => {
        // Stop showing tab details if there is any critical error
        if (criticalError) {
            return <div className="critical_error">
                <Warning msg={`${criticalError}`} reset={() => {}} />
            </div>
        }

        if (tab === Tabs.DOWNLOAD) {
            return <div className="downloads">
                {!packageX?.downloads?.length ? 
                    <div className="center-screen">There are no downloads available for {packageX?.title} yet!  The {packageX?.title} is under development, you will be updated once available.</div> 
                    : 
                    <>
                        {packageX.downloads.map((download, index) => <div key={index} className="download" onClick={() => downloadProduct(download)} >
                            <div className="download__title">Download {download.platform}</div>
                            {download.link ? 
                                <>
                                <div className="download__info">{download.info}</div>
                                <div className="download__icon"><i className={download.icon} /></div>
                                </>
                            : <Warning msg={`${download.info}`} reset={() => {}} />}
                        </div>)}
                    </>
                }
            </div>
        } else if (tab === Tabs.PAY || tab === Tabs.UPGRADE) {
            // select from package price range if there are multiple otherwise select only one if there is one only
            // if there are no prices then show message and stop proceeding to the payment stage
            if(!packageX?.canPurchase) {
                return <div className="pay">
                    <div className="center-screen">The {packageX?.title} software isn't available yet!  <p>Payment option will be activated once this software is available for purchase.</p></div> 
                </div>
            }

            if (packageX.prices?.length === 1) {
                // there is only one price type, use that as the default one
                setSelectedPackagePrice(packageX.prices[0]);
            }

            if(!selectedPackagePrice || !selectedPackageReadyToPay) {
                return <div className="pay">
                    <div className="center-screen">
                        <h3>Choose a Payment Plan {tab === Tabs.UPGRADE && "to Upgrade or Renew License"}</h3>
                        <div className="pay__license">                            
                            <div className="pay__license__plans">
                                {packageX.prices?.map((priceType, index) => <div key={index} onClick={() => setSelectedPackagePrice(priceType)} className={`pay__license__plans__plan ${selectedPackagePrice?.term === priceType.term ? 'g_button_green' : 'g_button'}`}>
                                    <div className="_row">
                                        <span className="attention">{priceType.term}</span>
                                        <span className="price">plan for {convertStripeAmountToNormal(priceType.price)} {DefaultCurrency}</span>
                                    </div>
                                </div>)}
                            </div>

                            <div className="pay__license__cart">
                                {!selectedPackagePrice ? <div className="pay__license__cart__empty">Please select a plan from the list of plans?</div> : 
                                <div> 
                                    <h3>Order Summary</h3>
                                    <div className="pay__license__cart__row bold">
                                        <span className="pay__license__cart__row__label">Subtotal</span>
                                        <span className="pay__license__cart__row__value blue">{convertStripeAmountToNormal(selectedPackagePrice.price)} {DefaultCurrency}</span>
                                    </div>
                                    <div className="pay__license__cart__row">
                                        <p className="small">You have chosen a {selectedPackagePrice.term} plan!</p>
                                    </div>

                                    <div className="pay__license__cart__action spaceT3">
                                        {selectedPackagePrice.mode === "subscription" && 
                                        <div className="pay__license__cart__row">
                                            <span className="pay__license__cart__row__label">Auto Renew?</span>
                                            <CheckBox autoChecked={autoRenew} classNames="pay__license__cart__row__value" lable="" toggle={setAutoRenew} />                                            
                                        </div>}
                                        <Button value="Buy Now" classNames="g_button_green blinking" callback={() => {setSelectedPackageReadyToPay(true)}} />
                                    </div>
                                </div>}
                            </div>
                            
                        </div>
                    </div> 
                </div>
                
            } else {
                
                return <div className="pay">
                    <>
                    {(strip) ? 
                        <ReactErrorBoundary title="Payment Processor Error" msg="The payment option is down for now!" >
                            <Elements stripe={strip} options={{amount:selectedPackagePrice.price, currency: "gbp", mode: (autoRenew && selectedPackagePrice.mode === "subscription") ? "subscription" : "payment"}}>
                                <CheckoutForm autoRenew={autoRenew} PackageName={packageX.name} PackagePriceTypeSelected={selectedPackagePrice} changeLicenseType={() => {setSelectedPackagePrice(undefined); setSelectedPackageReadyToPay(false);}} />
                            </Elements>
                        </ReactErrorBoundary>
                    : 
                    <Loader loading={true} msg="Please wait preparing payment options.." />
                    }
                    </>
                </div>
            }

        } else if (tab === Tabs.LICENSE) {
                return <div className="license">
                    
                    {(!entitlement || !isValid()) ? 
                        <div className="no_license">
                            <Warning msg={`You don't have an active license key for the ${packageX?.title} yet! To activate or upgrade your license key you need to purchase a plan.`} reset={() => {}} />
                        </div> 
                    :   <div className="license__area">
                            <p className="license__ok"><i className={Icons.Ok} /><span>Good news, you have a valid & active license for using {packageX?.title}!</span></p>
                            <div className="license__details">
                                <h2>License Details</h2>
                                <div className="license__details__info">
                                    <span>License Type</span>
                                    <span>{entitlement.licenseType}</span>
                                </div>
                                <div className="license__details__info">
                                    <span>Internal Ref</span>
                                    <span>#{entitlement.id}</span>
                                </div>
                                <div className="license__details__info">
                                    <span>Start Date</span>
                                    <span>{dateToStr(entitlement.startDate)}</span>
                                </div>
                                <div className="license__details__info">
                                    <span>End Date</span>
                                    <span>{dateToStr(entitlement.dueDate)}</span>
                                </div>
                                <div className="license__details__info">
                                    <span>Software Product</span>
                                    <span>{entitlement.packageName}</span>
                                </div>
                                <div className="license__details__info">
                                    <span>License Term</span>
                                    <span>{entitlement.paymentTerms}</span>
                                </div>
                                <div className="license__details__info">
                                    <span>License Key</span>
                                    <span><CopyToClipbaord className="g_error_line" content={(business?.licenseKey) ? business.licenseKey : "N/A - Some license keys are available on request only!"} /></span>
                                </div>
                                {entitlement.note && 
                                <div className="license__details__info spaceT10">
                                    <span>NOTE: </span>
                                    <span className="g_box_note">{entitlement.note}</span>
                                </div>}

                                {entitlement.extras?.length && 
                                <div className="license__details__info spaceT10">
                                    <span>Extra Options Included: </span>
                                    <ul>{entitlement.extras.map((extra, key) => <li key={key}>{extra.p_name}</li>)}</ul>
                                </div>}

                            </div>

                        </div>
                    }
                </div>
        
        } else if (tab === Tabs.PROCESSING){
            return <div className="processing">
                <div className="center-screen">
                    Please wait as your payment is still under process for {packageX?.title}!  
                    <p>If you think the payment process is taking more than expected time, you may <span className="g_link" onClick={resetPaymentProcessing} >click here</span> to try again.</p>
                </div>
            </div>

        } else {
            return;
        }
    }

    const showTabEntitlementTypeLabel = () => {
        if (isProcessing()) {
            return <Button classNames={`g_button paymenttab_${tab}`} value="Payment under process" callback={() => setTab(Tabs.PROCESSING)} />
        
        } else if (!isValid()) {
            return <Button classNames={`g_button paymenttab_${tab}`} value="Upgrade License" callback={() => setTab(Tabs.UPGRADE)} />

        } else {
            return <Button classNames={`g_button paymenttab_${tab}`} value="License Details" callback={() => setTab(Tabs.LICENSE)} />
        }
    }
    
    return (
        <div className="Page Package">
            {!packageX ? 
                <div className="center-screen Package__not">
                    <Warning msg={"No package selected. Please select an app!"} reset={() => {}} />
                    <Link to={Pages.OVERVIEW} title="Select an App">Select APP</Link>
                </div> 
            : 
                <div className="Package__container">
                    <div className="Package__container__header">
                        {packageX.icon && <Icon icon={packageX.icon} />}
                        <h2>{packageX.title}</h2>
                        <p>{packageX.summary}</p>
                    </div>
                    {loading ? 
                        <div className="Package__container__loading center-screen">
                            Please wait loading..
                        </div> 
                    : 
                        <div className="section">
                            {(!entitlement || !isValid()) &&  
                                <div className="no_license">
                                    <Warning msg={`You don't have an active license key for the ${packageX.title} yet! To upgrade your license you need to purchase a plan.`} reset={() => {}} />
                                </div> 
                            }

                            {/** Tabs */}
                            <div className="actions">
                                {(entitlement) ? showTabEntitlementTypeLabel() : <Button classNames={`g_button pay_${tab}`} value="Buy License" callback={() => setTab(Tabs.PAY)} />}
                                
                                <Link className={`g_button about_${tab}`} to={packageX.aboutLink} target="_blank">About the Software</Link>
                                <Button classNames={`g_button download_${tab}`} value="Download Options" callback={() => setTab(Tabs.DOWNLOAD)} />
                            </div>

                            <div className="tabarea">
                                {!tab ? 
                                    <div className="tabarea__notselected center-screen">
                                        Need to know more about {packageX.title} - click the above appropriate button for details!
                                    </div> 
                                    : 
                                    <div className="tabarea__contents">
                                        {showSelectedTabContents()}
                                                                                
                                        {entitlementWarningMsg && <div className="license__msg">
                                            <Warning msg={entitlementWarningMsg} reset={() => {}} />
                                            <p className="small">In order to upgrade your subscription in advance please contact either the Ebmbook Team or from whom you have purchased this software!</p>
                                        </div>}
                                    </div>
                                }
                            </div>

                        </div>
                    }

                    {showConfirmDownload && <ConfirmWindow okTxt="Download" title="Download Software" msg="Please confirm you would like to download this software?" confirm={() => {window.location.href = showConfirmDownload.link!;setShowConfirmDownload(undefined);}} cancel={() => {setShowConfirmDownload(undefined);}} />}
                </div>
            }
        </div>
    )
}
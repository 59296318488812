import React from "react";
import { Icons } from "src/config/icons";
import { useHeader } from "src/hooks/useHeader";
import { message } from "src/messages";
import "./index.scss"
import Profile from "./Profile";
import Password from "./Password/Password";

export default function Settings() {
    // set page title in header
    useHeader({title: message.Settings});

    return (
        <div className="Settings left-contents">
            <div className="Settings__section">
                <h2><i className={Icons.Person} />{message.Profile}</h2>
                <Profile />
            </div>
            <div className="Settings__section">
                <h2><i className={Icons.Password} />{message.ResetPassword}</h2>
                <Password />
            </div>
        </div>
    )
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ActionTracker } from '../../interfaces';

interface IActionState {
    actions: ActionTracker[]
}

const initialState: IActionState = {
    actions: [] as ActionTracker[]
}

const actionSlicer = createSlice({
    name: 'action',
    initialState,
    reducers: {
        action(state, action: PayloadAction<ActionTracker>) {
            // cache locally the status of this action
            action.payload.time = new Date().getTime();
            state.actions.push(action.payload);
        },
        /** reset all the actions */
        resetActions(state) {
            state.actions = [];
        }
    }
});

export const { action, resetActions } = actionSlicer.actions;
export default actionSlicer.reducer;

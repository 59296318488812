import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { LoginURL, SignupURL } from 'src/api/Endpoints';
import { ActionTracker, IBusiness, IUser } from 'src/interfaces';
import { getUser, initUser, removeUser, setUser } from '../cache';

export const userLogin = createAsyncThunk("user/login", async (params: any) => {
    const res = await axios.post(LoginURL, params);
    return res.data;
});
export const userSignup = createAsyncThunk("user/signup", async (params: any) => {
    const res = await axios.post(SignupURL, params);
    return res.data;
});

const initialUserState = (getUser()) ? {
    isLoggedIn: true,
    user: initUser() as IUser,
    actions: [] as ActionTracker[]
} : {
    isLoggedIn: false,
    user: null,
    actions: [] as ActionTracker[]
}

const userSlicer = createSlice({
    name: 'user',
    initialState: initialUserState,
    reducers: {
        isLoggedIn(state){
            state.isLoggedIn = false;
        },
        login(state, action: PayloadAction<IUser>) {
            setUser(action.payload);
            state.user = action.payload;
            state.isLoggedIn = true;
        },
        logout(state) {
            removeUser();
            state.user = null;
            state.isLoggedIn = false;
        },
        update(state, action: PayloadAction<IUser>){
            // for security reason don't save business details in the cookies
            setUser({...action.payload, business: undefined});
            state.user = action.payload;
            state.isLoggedIn = true;
        },
        /** don't store business details in cookie */
        updateBusiness(state, action: PayloadAction<IBusiness>){
            if (state.user) {
                state.user.business = action.payload;                
            }
        }
    },
    extraReducers: (builder) => {
        builder.addCase(userSignup.fulfilled, (state, action) => {
            //state.signupPayload = action.payload;
        })
    }
});

export const { isLoggedIn, login, logout, update, updateBusiness } = userSlicer.actions;
export default userSlicer.reducer;

import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PaymentStatusURL } from "src/api/Endpoints";
import { sendRequest } from "src/api/SendRequest";
import Loader from "src/components/Loader/Loader";
import { RequestType } from "src/types";

import "./PaymentStatus.scss";
import { IPurchase } from "src/interfaces";
import { CURRENCY } from "src/config";
import usePackages from "src/hooks/usePackages";
import { dateToStr } from "src/lib";

//https://stripe.com/docs/payments/quickstart?lang=node
//see url for details
export default function PaymentStatus() {
    
    const [msg, showMessage] = useState("");
    const [paymentResult, setPaymentResult] = useState<IPurchase>(); 
    const {packageDetail, loadPackage} = usePackages();

    const [searchParams,] = useSearchParams();
    //const clientSecret = searchParams.get("payment_intent_client_secret");
    //const payment_intent = searchParams.get("payment_intent");
    const orderID = searchParams.get("order"); // order id

    useEffect(() => {
        if (orderID) {    
            // Fetches the payment intent status after payment submission
            const checkStatus = async () => {
                if (!(orderID)) {
                    return;
                }
        
                sendRequest({api: `${PaymentStatusURL}/${orderID}`, rtype: RequestType.GET}).then((res) => {
                    if (res.status === 200) {
                        console.log(`valid intent: `, res);
                        setPaymentResult(res.data);
                        const paymentStatusRes = res.data.status;
                        
                        switch (paymentStatusRes) {
                            case "succeeded":
                            showMessage("Payment processed successfully!");
                            break;
                            case "processing":
                            showMessage("Your payment is processing.");
                            break;
                            case "requires_payment_method":
                            showMessage("Your payment was not successful, please try again.");
                            break;
                            default:
                            showMessage("Something went wrong.");
                            break;
                        }
        
                    } else {
                        console.warn(`invalid intent: `, res);
                        if (res.data?.exception?.type === "StripeInvalidRequestError") { // comes from stripe
                            showMessage("No record found - missing request details!");                    
                        } else {
                            showMessage("Something went wrong - no record found!");
                        }
                    }
                }); 
                
            }
            checkStatus();
        } else {
            showMessage("Sorry no orderID found!")
        }
    }, [orderID]);

    useEffect(() => {
        if (paymentResult) {
            loadPackage(paymentResult.packageName);
        }
    }, [paymentResult, loadPackage])
    
    return (
        <div className="PaymentStatus center-screen">
            {!msg ? <Loader loading={true} msg="Please wait checking your transaction status.." /> 
            : 
            <div className="PaymentStatus__result">
                <div className={`PaymentStatus__result__default PaymentStatus__result__${paymentResult?.status}`}>{msg}</div>
                {paymentResult && 
                    <div className="PaymentStatus__result__record">
                        <h2 className="line">Transaction Details</h2>
                        <div className="_data">
                            <label>Transaction ID</label>
                            <span>{paymentResult.transactionId}</span>
                        </div>
                        <div className="_data">
                            <label>Order ID</label>
                            <span>#{paymentResult.id}</span>
                        </div>
                        <div className="_data">
                            <label>Licensed for</label>
                            <span>{packageDetail?.title||paymentResult.packageName}</span>
                        </div>
                        <div className="_data">
                            <label>Order Type</label>
                            <span>{paymentResult.paymentTerms} {paymentResult.licenseType}</span>
                        </div>
                        <div className="_data">
                            <label>Current Status</label>
                            <span className="line bold ">{paymentResult.status}</span>
                        </div>
                        <div className="_data">
                            <label>Amount</label>
                            <span>{paymentResult.totalAmount} {CURRENCY}</span>
                        </div>
                        <div className="_data">
                            <label>Created on</label>
                            <span>{dateToStr(paymentResult.startDate || paymentResult.createdAt)}</span>
                        </div>
                        {(paymentResult.licenseType === "subscription" && paymentResult.dueDate) && <div className="_data">
                            <label>License Expiry Date</label>
                            <span>{dateToStr(paymentResult.dueDate)}</span>
                        </div>
                        }
                    </div>
                }
            </div>
            }
        </div>
    )
}

import React, { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import { ResetConfirmURL, UserURL } from "src/api/Endpoints";
import { sendRequest } from "src/api/SendRequest";
import Button from "src/components/Button/Button";
import Warning from "src/components/Warning/Warning";
import { Icons } from "src/config/icons";
import { isPassword } from "src/lib";
import { message } from "src/messages";
import { Pages, RequestType } from "../../types/index";
import './ResetConfirm.scss';
import { useHeader } from "src/hooks/useHeader";
import TextInput from "src/components/TextInput/TextInput";
import Loader from "src/components/Loader/Loader";

export default function ResetConfirm() {
    // set page title in header
    useHeader({title: "Confirm Reset Password"});
    const [ password, setPassword ] = useState("");
    const [ rePassword, setRePassword ] = useState("");
    
    const [searchParams,] = useSearchParams();
    const token = searchParams.get("token");
    const uid = searchParams.get("uid");
    const [isValidToken, setValidToken] = useState(false);

    const [errorProp, setErrorProp] = useState("");
    const [ error, setError ] = useState("");
    const [ isResetConfirmed, setResetConfirmed ] = useState(false);
    const [ loading, setLoading ] = useState(false);

    useEffect(() => {
        // validate reset link
        if (token) {            
            setLoading(true);
            sendRequest({api: `${UserURL}check_token`, params: {uid}, rtype: RequestType.POST, cacheOff: true, tempToken: token, forceLogoutOff: true}, message.ErrorOther).then((res) => {
                if (res.status === 200) {
                    setValidToken(true);
                }

            }).finally(() => setLoading(false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const buttonClick = () => {
        // initial validation
        setError(""); // reset error
        if (!token) {
            setError("Valid token required");
        } else if (!isPassword(password)) {
            setError(message.PasswordRequired);
            setErrorProp("password");
        } else if (password !== rePassword) {
            setError("Password should match!");
            setErrorProp("rePassword");
        } else {
            setLoading(true);
            // send data to API
            // setRequest({api: LoginURL, params: {email, password}, rtype: RequestType.POST});
            sendRequest({api: ResetConfirmURL, params: {password, rePassword}, rtype: RequestType.POST, cacheOff: true, tempToken: token}, message.ErrorOther).then((res) => {
                console.log(`status (${res.status}) ${res.statusText}`);
                if (res.status === 200) {
                    // reset link sent.
                    setResetConfirmed(true);

                } else if (res.status === 429) {
                    setValidToken(false);
                    setError("Your password already changed & this link isn't valid anymore.  Please login or send new reset password request!");
                    
                } else {
                    setError(res.statusText);
                }

                setLoading(false);
            });
        }
    }

    if (!token) {
        return (
            <div className="Reset center-screen">
                <Warning msg="Reset password token is required!" reset={() => {}} />
                <div className="Reset__bottomtext spaceT5">
                    <p>
                        You may <Link to={Pages.LOGIN}>login</Link> if you don't have the token!
                    </p>
                </div>
            </div>
        );

    } else if (loading) {
        return (
            <div className="Reset center-screen">
                <Loader loading={true} msg="Please wait checking token.." />
            </div>
        );

    }  else if (!isValidToken) {
        return (
            <div className="Reset center-screen">
                <Warning msg="This reset password link is invalid or might be expired." reset={() => {}} />                    
                <div className="Reset__bottomtext spaceT5">
                    <p>
                        Get a new <Link to={Pages.RESET}>reset password</Link> link!
                    </p>
                    <p>
                        Or, just <Link to={Pages.LOGIN}>login</Link>!
                    </p>
                </div>
            </div>
        )        
    } 

    if (isResetConfirmed) {

        return (
            <div className="Reset center-screen">
                <h1 className="Reset__title">Password Changed</h1>
                <form>
                    <div className="Reset__done">
                        <p>Done, your Ebmbook Central account password has been changed successfully. </p>
                    </div>
                    
                    <div className="Reset__bottomtext spaceT5">
                        <p>
                            You may <Link to={Pages.LOGIN}>Login</Link> with your new password!
                        </p>
                    </div>
                </form>
            </div>
        )        
    }

    return (
        <div className="Reset center-screen">
            <h1 className="Reset__title">Confirm Reset Password</h1>
            <p className="Reset__subtitle">Your Ebmbook Central account password will change once confirmed.</p>
            <form>

                <TextInput isError={errorProp === "password"} lable={"New Password"} placeholder={""} isPassword={true} setValue={setPassword} />
                <TextInput isError={errorProp === "rePassword"} lable={"Re-New Password"} placeholder={""} isPassword={true} setValue={setRePassword} />
                <Warning msg={error} reset={() => setError("")} />
                <Button callback={() => buttonClick()} value={"Confirm"} icon={Icons.Login} classNames="spaceT10" loading={loading} />

                <div className="Reset__bottomtext spaceT5">
                    <p>
                        Or, just ignore & <Link to={Pages.LOGIN}>Login</Link> with old password!
                    </p>
                </div>
            </form>
        </div>
    )
}

/**
 * Application themes
 */
export enum Theme {
    LIGHT = 'light',
    DARK = 'dark'
}

export enum Pages {
    OVERVIEW = '/overview',
    REGISTRATION = '/register',
    LOGIN = '/login',
    RESET = '/reset',
    RESET_CONFIRM = '/confirm_reset',
    INVENTORY = '/inventory',
    MESSAGES = '/messages',
    ACCOUNT = "/account",
    TOOLS = '/amazon_tools',
    LOGOUT = '/logout',
    HELP = '/help',
    SUBSCRIPTIONS = '/subscriptions',
    GETTING_STARTED = '/getting_started',
    PACKAGES = "/packages",
    PAYMENT = "/packages/payment",
    TOOLS_RESEARCH = '/amazon_tools/research_tool',
    TOOLS_SHORTLIST = '/amazon_tools/shortlist_products',
    TOOLS_TRACK = '/amazon_tools/track_top_products',
    TOOLS_EXTENSION = '/amazon_tools/extension'
}


export enum Links {
    CONTACT_US = 'https://ebmbook.com/contact/'
}

export enum EbmbPackage {
    PRO = "propos",
    STARTER = "starterpos",
    SMART_POS = "smartpos",
    AMAZONTOOL = "amazon_management_tool"
}

export enum RequestType {
    POST = "POST",
    GET = "GET",
    DELETE = "DELETE",
    PUT = "PUT"
}

export enum ActionTypes {
    ALERT,
    PROFILE_UPDATE,
    PASSWORD_UPDATE,
    EMAIL_RESET,
    REGISTERED,
    PERSONAL_SETTING
}

export enum CountryCodes {
    UK = "UK",
    US = "US",
    FR = "FR",
    GE = "GE",
    IT = "IT",
    ES = "ES",
    IN = "IN",
    BR = "BR",
    CA = "CA",
    CN = "CN"
}

export enum BusinessType {
    RETAIL = "Retail Store",
    RESTAURANT = "Food & Restaurant"
}

export type PriceType = "payment" | "setup" | "subscription" | undefined;

export type PurchaseStatus = "succeeded" | "requires_payment_method" | "requires_confirmation" | "requires_action" | "processing" | "canceled";

export const businessTypes: BusinessType[] = [BusinessType.RESTAURANT, BusinessType.RETAIL];
